export const lettersList: string[] = [
  "ا",
  "أ",
  "إ",
  "ب",
  "ت",
  "ث",
  "ج",
  "ح",
  "خ",
  "د",
  "ذ",
  "ر",
  "ز",
  "س",
  "ش",
  "ص",
  "ض",
  "ط",
  "ظ",
  "ع",
  "غ",
  "ف",
  "ق",
  "ك",
  "ل",
  "م",
  "ن",
  "ه",
  "ة",
  "و",
  "ؤ",
  "ي",
  "ئ",
  "ى",
  "Backspace",
  "Enter"
];

export const letterColors = {
  letterInitial: "bg-white",
  letterAbsent: "bg-gray-500",
  letterExist: "bg-yellow-500",
  letterRight: "bg-green-700"
};
