// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { doc, setDoc } from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";
import { wordsList } from "./data/words-list";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAPiQYQK5JjpAs3TyaKEBejBBv3uERZPxY",
  authDomain: "jareed-wordle.firebaseapp.com",
  projectId: "jareed-wordle",
  storageBucket: "jareed-wordle.appspot.com",
  messagingSenderId: "139662005313",
  appId: "1:139662005313:web:2d3d47476b8a8f2a52e2c0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const db = getFirestore(app);
// wordsList.forEach(async (word) =>  {
//     await setDoc(doc(db, "words", word), {
//         word,
//         used: false
//     });
// });


export default app;


