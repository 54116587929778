import { mainModule } from "process";
import app from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";

export const wordsList: string[] = [
  "تحلية",
  "تلبية",
  "عندما",
  "لماذا",
  "الأمر",
  "تفاحة",
  "الوقت",
  "اليوم",
  "الرجل",
  "لدينا",
  "الناس",
  "علينا",
  "تفاهة",
  "واحدة",
  "مرحبا",
  "العمل",
  "عليها",
  "المال",
  "الشيء",
  "هؤلاء",
  "اعتقد",
  "السيد",
  "الذين",
  "مشكلة",
  "بحاجة",
  "الشخص",
  "مالذي",
  "النار",
  "الباب",
  "حياتي",
  "انتظر",
  "ثلاثة",
  "والآن",
  "السبب",
  "كانوا",
  "كبيرة",
  "دولار",
  "هنالك",
  "جميلة",
  "دائما",
  "ثانية",
  "رائعة",
  "الامر",
  "دقيقة",
  "الأرض",
  "بسرعة",
  "طريقة",
  "لأنني",
  "الخاص",
  "لديها",
  "الخير",
  "بينما",
  "لكنني",
  "المرة",
  "متأكد",
  "الموت",
  "صغيرة",
  "صديقي",
  "الأقل",
  "علاقة",
  "دقائق",
  "نهاية",
  "جديدة",
  "لديهم",
  "الأول",
  "عزيزي",
  "لنذهب",
  "الجنس",
  "البيت",
  "وماذا",
  "الطفل",
  "قليلا",
  "الليل",
  "البعض",
  "بأنني",
  "طويلة",
  "السجن",
  "والدك",
  "والدي",
  "نحتاج",
  "العام",
  "حياتك",
  "مايكل",
  "الماء",
  "عليهم",
  "معرفة",
  "نتحدث",
  "دكتور",
  "بخصوص",
  "الآخر",
  "النوم",
  "الحال",
  "الحرب",
  "كثيرا",
  "لكنها",
  "حاولت",
  "عائلة",
  "انظري",
  "الفتى",
  "رسالة",
  "حينما",
  "حقيقي",
  "مجنون",
  "فرانك",
  "دعونا",
  "زوجتي",
  "امرأة",
  "حقيقة",
  "جميعا",
  "بأنها",
  "الجيد",
  "إنتظر",
  "بيننا",
  "مدينة",
  "بعضنا",
  "انتهى",
  "النوع",
  "الملك",
  "إليها",
  "الوضع",
  "بنفسك",
  "ولكني",
  "مليون",
  "عملية",
  "فعلته",
  "ولكنه",
  "دعيني",
  "بعدها",
  "الصعب",
  "النظر",
  "للعمل",
  "مشاكل",
  "الشمس",
  "بنفسي",
  "بعيدا",
  "بكثير",
  "جريمة",
  "الجزء",
  "البحث",
  "مستعد",
  "مختلف",
  "بأننا",
  "عليكم",
  "لأنها",
  "زوجتك",
  "مقابل",
  "الخطأ",
  "السنة",
  "منتصف",
  "ميلاد",
  "نفسها",
  "موجود",
  "طالما",
  "رأيته",
  "ممتاز",
  "القتل",
  "مفهوم",
  "لفترة",
  "لطيفة",
  "فعلها",
  "مازال",
  "والذي",
  "العرض",
  "حوالي",
  "لكننا",
  "اسمعي",
  "الصور",
  "الوغد",
  "القصة",
  "مازلت",
  "إعادة",
  "صديقك",
  "قائمة",
  "حينها",
  "بماذا",
  "ولكنك",
  "مجددا",
  "إمرأة",
  "عندها",
  "منزلي",
  "كثيرة",
  "البحر",
  "رؤيتك",
  "إيجاد",
  "البشر",
  "للأبد",
  "ارجوك",
  "إلينا",
  "خائفة",
  "دعينا",
  "إطلاق",
  "كلارك",
  "الجيش",
  "القلب",
  "حياته",
  "منطقة",
  "الغير",
  "إثنان",
  "اثنان",
  "القوة",
  "بجانب",
  "الكلب",
  "الهدف",
  "القول",
  "احتاج",
  "صندوق",
  "العيش",
  "لأننا",
  "ديفيد",
  "مكانك",
  "وظيفة",
  "ابتعد",
  "وجدنا",
  "مثيرة",
  "لاحقا",
  "غريبة",
  "بالله",
  "الخلف",
  "قريبا",
  "حبيبي",
  "كلانا",
  "الأمن",
  "لديكم",
  "الصوت",
  "الشاب",
  "طريقي",
  "الكرة",
  "رائحة",
  "ليسوا",
  "زوجته",
  "ماهذا",
  "واحده",
  "قادمة",
  "لربما",
  "اللعب",
  "الرقص",
  "القبض",
  "والتي",
  "ملابس",
  "صديقة",
  "ابنتي",
  "لنفسك",
  "اثنين",
  "متأخر",
  "مدرسة",
  "نحاول",
  "المهم",
  "للبيت",
  "لرؤية",
  "الضوء",
  "اتصلت",
  "بطاقة",
  "بداية",
  "الشعر",
  "اذهبي",
  "روبرت",
  "الرغم",
  "كاملة",
  "إنتهى",
  "الرقم",
  "وهناك",
  "شخصية",
  "الخطة",
  "كيفية",
  "عظيمة",
  "وصلنا",
  "فتيات",
  "والتر",
  "القدم",
  "اتمنى",
  "مثلما",
  "وربما",
  "العمر",
  "حصلنا",
  "اللحم",
  "مسألة",
  "بعضهم",
  "باريس",
  "القمر",
  "معذرة",
  "عاهرة",
  "قالوا",
  "بأمان",
  "جاهزة",
  "حماية",
  "اسمها",
  "حالما",
  "بمجرد",
  "طائرة",
  "الألم",
  "كابتن",
  "مرتين",
  "بوسعي",
  "طبيعي",
  "انتهت",
  "موافق",
  "مناسب",
  "مشغول",
  "طريقه",
  "غاضبة",
  "منزلك",
  "وداعا",
  "لكنهم",
  "ذاهبة",
  "بشأنه",
  "استمع",
  "مـاذا",
  "احاول",
  "الولد",
  "قاموا",
  "للحظة",
  "الزمن",
  "الشهر",
  "وكانت",
  "الضغط",
  "الثلج",
  "وكذلك",
  "موضوع",
  "محظوظ",
  "الغبي",
  "قليلة",
  "الخوف",
  "القدر",
  "الخطب",
  "للهول",
  "الأبد",
  "الشاي",
  "علامة",
  "الجثة",
  "ولهذا",
  "اريدك",
  "الرأس",
  "نفعله",
  "بنفسه",
  "مكتبي",
  "قصيرة",
  "الصيف",
  "حقيبة",
  "لابأس",
  "النهر",
  "بأنهم",
  "إشارة",
  "السحر",
  "الشكل",
  "والان",
  "لاشيء",
  "لأنهم",
  "قديمة",
  "اتحدث",
  "مارتن",
  "اتصال",
  "اخبرك",
  "البيض",
  "الوجه",
  "الكون",
  "مستوى",
  "بسيطة",
  "فعلنا",
  "وكأنه",
  "كارتر",
  "رؤيته",
  "قابلت",
  "مكانه",
  "رأيتك",
  "اعطني",
  "كفاية",
  "غادرت",
  "لدرجة",
  "وجدته",
  "فائدة",
  "قاعدة",
  "لوحدي",
  "بعيدة",
  "السبت",
  "عينيك",
  "جامعة",
  "مريضة",
  "والده",
  "نعتقد",
  "ننتظر",
  "الاسم",
  "الطرق",
  "حادثة",
  "السهل",
  "قادرة",
  "وسيلة",
  "للنوم",
  "إنقاذ",
  "استمر",
  "الوحش",
  "كلمات",
  "الارض",
  "وحيدة",
  "الفرق",
  "رايان",
  "مفتاح",
  "الكذب",
  "الحفل",
  "الهرب",
  "الثقة",
  "كافية",
  "الفوز",
  "الكتب",
  "بعدما",
  "عالية",
  "شاحنة",
  "عنوان",
  "ايتها",
  "مليئة",
  "القسم",
  "الفور",
  "محامي",
  "زوجها",
  "وكالة",
  "رائعا",
  "لازلت",
  "واثقة",
  "للأسف",
  "هاتفي",
  "قيادة",
  "الحين",
  "اشياء",
  "المرح",
  "عملنا",
  "بداخل",
  "قنبلة",
  "الرحب",
  "عندنا",
  "الثمن",
  "بدأنا",
  "اللون",
  "معروف",
  "ولاية",
  "لاحظت",
  "إذهبي",
  "قانون",
  "لأجلك",
  "ذهبنا",
  "وقالت",
  "الاقل",
  "السطح",
  "إليهم",
  "الفصل",
  "مثالي",
  "السمك",
  "اتعلم",
  "السفر",
  "نتكلم",
  "كارين",
  "فضلكم",
  "عبارة",
  "نتيجة",
  "حياتى",
  "الروح",
  "براين",
  "هاتفك",
  "لتكون",
  "الاول",
  "لنفسي",
  "شاهدت",
  "إبتعد",
  "بسرعه",
  "واضحة",
  "الذهب",
  "قريبة",
  "وأبوس",
  "مشروع",
  "قراءة",
  "فرنسا",
  "اصبحت",
  "القرن",
  "للبحث",
  "إدارة",
  "ليكون",
  "الأمل",
  "واحدا",
  "جزيرة",
  "بمكان",
  "بوجود",
  "لأكون",
  "بهدوء",
  "الهند",
  "جماعة",
  "السير",
  "ابنتك",
  "معقول",
  "مسرور",
  "بحلول",
  "الشكر",
  "العدو",
  "وقتها",
  "جزيلا",
  "ديريك",
  "معركة",
  "لوحدك",
  "ولكنى",
  "حيوان",
  "جعلني",
  "الصبي",
  "غرفتي",
  "ممتعة",
  "الحكم",
  "صديقى",
  "عصابة",
  "السوق",
  "احدهم",
  "انتبه",
  "إسمعي",
  "الغضب",
  "الظهر",
  "للخلف",
  "دعوني",
  "عمرها",
  "بجدية",
  "الدفع",
  "الشقة",
  "البدء",
  "القلق",
  "الخمر",
  "كتابة",
  "الكعك",
  "إيقاف",
  "الخبر",
  "باركر",
  "لازال",
  "مفتوح",
  "المرض",
  "اليها",
  "كيفين",
  "ليلتك",
  "رائعه",
  "البنك",
  "الأحد",
  "لمكان",
  "هيلين",
  "البلد",
  "رسائل",
  "الجسم",
  "للمرة",
  "فيليب",
  "جرائم",
  "العلم",
  "مذهلة",
  "لأجلي",
  "صعوبة",
  "للعيش",
  "مشاعر",
  "عشرون",
  "مرحلة",
  "الشيئ",
  "خطيرة",
  "منطقي",
  "الشرف",
  "اطلاق",
  "الصين",
  "فقدان",
  "المرء",
  "الخطر",
  "مكتوب",
  "النفس",
  "إجراء",
  "مفقود",
  "نتائج",
  "مؤخرة",
  "لورين",
  "الشعب",
  "عديدة",
  "إرسال",
  "رصاصة",
  "مسموح",
  "العقل",
  "إليكم",
  "المطر",
  "ماتوا",
  "الطقس",
  "مقدار",
  "حديقة",
  "الجثث",
  "كارول",
  "جوليا",
  "العين",
  "المشي",
  "طويلا",
  "مارتي",
  "خاطئة",
  "بوضوح",
  "لنبدأ",
  "مشكله",
  "منزله",
  "لصالح",
  "رئيسي",
  "اختفى",
  "القصص",
  "وجدوا",
  "الجسر",
  "متزوج",
  "إياها",
  "اتذكر",
  "هارفي",
  "متأسف",
  "صحيحة",
  "وهكذا",
  "فعلوا",
  "طريقك",
  "شديدة",
  "قتلته",
  "مشروب",
  "إستمع",
  "قتلها",
  "مسلسل",
  "البطل",
  "قتلوا",
  "خاصتي",
  "مسافة",
  "دائرة",
  "الدور",
  "بعضها",
  "الملف",
  "لعينة",
  "بصورة",
  "السوء",
  "مكاني",
  "بحاجه",
  "النصف",
  "الدين",
  "بدونك",
  "صباحا",
  "الورق",
  "ماعدا",
  "طاولة",
  "شعورك",
  "بتحسن",
  "ماريا",
  "بوسعك",
  "شهادة",
  "فيديو",
  "إنسان",
  "بأسرع",
  "إجازة",
  "كنتما",
  "رؤيتي",
  "الحلم",
  "الخبز",
  "وأريد",
  "ذهبوا",
  "صدقني",
  "بحالة",
  "الشأن",
  "كأنها",
  "معجبة",
  "ميشيل",
  "للناس",
  "هادئة",
  "إتصال",
  "لوكاس",
  "نظرية",
  "شهرين",
  "زيادة",
  "الدعم",
  "لحظات",
  "لوحده",
  "الشىء",
  "السيء",
  "الصيد",
  "إنهاء",
  "القذر",
  "صحيحا",
  "بسببك",
  "الممر",
  "العسل",
  "والأن",
  "مساعد",
  "وزارة",
  "زجاجة",
  "لشراء",
  "وكأنك",
  "محتمل",
  "الغاز",
  "جنرال",
  "جائزة",
  "والدة",
  "فقدنا",
  "الحمل",
  "غرفتك",
  "استطع",
  "العكس",
  "مؤخرا",
  "منظمة",
  "رجالي",
  "عادية",
  "إلقاء",
  "رايلي",
  "إحضار",
  "إبنتي",
  "مثلها",
  "الشرب",
  "إيريك",
  "العقد",
  "عزيزى",
  "الاخر",
  "جميله",
  "الوطن",
  "قاسية",
  "إنتهت",
  "الجبل",
  "عبقري",
  "بيضاء",
  "الوعي",
  "دينيس",
  "الحمض",
  "لانني",
  "رجالك",
  "العنف",
  "إنهما",
  "هنـاك",
  "فخورة",
  "حاولي",
  "ليمون",
  "الرأي",
  "دقيقه",
  "إثبات",
  "ثواني",
  "إصلاح",
  "حمقاء",
  "خسارة",
  "للخطر",
  "الحجم",
  "إنظري",
  "لأننى",
  "المره",
  "نغادر",
  "نعرفه",
  "الشرق",
  "زيارة",
  "البقر",
  "اللغة",
  "لسماع",
  "بارني",
  "إتصلت",
  "قلبية",
  "وافقت",
  "ليندا",
  "نصيحة",
  "خاصتك",
  "كبيره",
  "قواعد",
  "راندي",
  "ميامي",
  "لأجله",
  "الجنة",
  "جائعة",
  "بينهم",
  "مخطئة",
  "مهتمة",
  "القصر",
  "بوسطن",
  "حزينة",
  "القبو",
  "اختفت",
  "بتهمة",
  "اكتشف",
  "اخبار",
  "فارغة",
  "إيثان",
  "صاحبي",
  "عشرين",
  "مذكرة",
  "مسؤول",
  "اسبوع",
  "حاليا",
  "حقيقى",
  "مكتبك",
  "الصحف",
  "مقبول",
  "بأفضل",
  "نتمكن",
  "بجوار",
  "باردة",
  "اللوم",
  "إضافة",
  "إثارة",
  "السكر",
  "معجزة",
  "عليكي",
  "الصدر",
  "نائمة",
  "كأنني",
  "الأخر",
  "جوردن",
  "ولذلك",
  "ماثيو",
  "الجسد",
  "الجلد",
  "ناحية",
  "جيسون",
  "الآلة",
  "للقلق",
  "بكلمة",
  "مميزة",
  "خارجا",
  "للموت",
  "انظرى",
  "نظيفة",
  "ماحدث",
  "الحذر",
  "إنذار",
  "عملاء",
  "بشأنك",
  "خنزير",
  "كارثة",
  "احتجت",
  "الحبل",
  "عامين",
  "مزرعة",
  "بقليل",
  "صغيره",
  "ننتهي",
  "القطع",
  "رأينا",
  "نتحرك",
  "مضحكة",
  "الغرب",
  "ممنوع",
  "اطفال",
  "ضعيفة",
  "كذالك",
  "الدرج",
  "كلامك",
  "الأكل",
  "نانسي",
  "المدة",
  "مثلنا",
  "السيف",
  "الميت",
  "برفقة",
  "الصمت",
  "للأمر",
  "الإسم",
  "لمجرد",
  "لأكثر",
  "بسلام",
  "نلتقي",
  "اتعرف",
  "فطيرة",
  "دواعي",
  "للوقت",
  "انتما",
  "الحمد",
  "حمراء",
  "حرارة",
  "المحل",
  "غلطتي",
  "مباشر",
  "للسجن",
  "الوزن",
  "صوفيا",
  "العدد",
  "ماكان",
  "مطلقا",
  "مارثا",
  "مغلقة",
  "وليست",
  "حالات",
  "فعلتي",
  "خزانة",
  "فتاتي",
  "بعضكم",
  "مصابة",
  "ملفات",
  "مشترك",
  "محترف",
  "بايبر",
  "اعترف",
  "كبيرا",
  "مجهول",
  "مبروك",
  "الحدث",
  "الجهة",
  "ايجاد",
  "الجبن",
  "ضروري",
  "لبقية",
  "اتصلي",
  "شريكي",
  "فريدي",
  "الأمس",
  "براون",
  "قالته",
  "النقل",
  "مؤسسة",
  "إخبار",
  "متعبة",
  "إسمها",
  "صاحبة",
  "العبث",
  "السقف",
  "اتفاق",
  "جوزيف",
  "اللهي",
  "فماذا",
  "اجلسي",
  "رفيقي",
  "كلامي",
  "جراحة",
  "لنخرج",
  "البيع",
  "وجهها",
  "الينا",
  "احضرت",
  "القطط",
  "شارلي",
  "السلة",
  "الجرح",
  "نتوقف",
  "حولها",
  "ثانيه",
  "للسيد",
  "افعله",
  "الطلب",
  "صديقه",
  "للرجل",
  "رجاءا",
  "اخرجي",
  "ويبدو",
  "وحدها",
  "منهما",
  "مشتبه",
  "النيل",
  "عملها",
  "إغلاق",
  "هاتفه",
  "إجابة",
  "رئيسة",
  "جاستن",
  "طبيعة",
  "رعاية",
  "دراجة",
  "بصمات",
  "طبيبة",
  "الضرر",
  "اللذي",
  "الغرف",
  "موريس",
  "لهناك",
  "قلتها",
  "الجدد",
  "بحرية",
  "الإله",
  "اخترت",
  "القرد",
  "اعتدت",
  "رأسها",
  "عاصفة",
  "القفز",
  "الحجر",
  "معينة",
  "مولاي",
  "البنت",
  "الشبح",
  "إثنين",
  "جيرمي",
  "عارية",
  "حفلات",
  "منكما",
  "طارئة",
  "بيتزا",
  "إليوت",
  "روسيا",
  "زوجتى",
  "وضعها",
  "إيفان",
  "السعر",
  "انطلق",
  "مايلز",
  "مشهور",
  "بقايا",
  "احتفظ",
  "العدل",
  "السفن",
  "مولدر",
  "السري",
  "شيطان",
  "برأيك",
  "هاربر",
  "غالبا",
  "الفعل",
  "الحزن",
  "مساحة",
  "وسائل",
  "نافذة",
  "بوارو",
  "اعتذر",
  "إعلان",
  "اثناء",
  "وضعنا",
  "النور",
  "لكننى",
  "المدى",
  "مبكرا",
  "محكمة",
  "متحمس",
  "داخلي",
  "جنوني",
  "طفلتي",
  "ناثان",
  "الديك",
  "فظيعة",
  "جيفري",
  "الضخم",
  "متعلق",
  "الزوج",
  "نارية",
  "وجودك",
  "رئيسك",
  "حافلة",
  "اليكس",
  "بشأني",
  "القوى",
  "عودوا",
  "بسببي",
  "لبضعة",
  "فكرتي",
  "جديده",
  "لطيفا",
  "لانها",
  "الخشب",
  "الفحص",
  "الحجز",
  "وأنتم",
  "اتكلم",
  "مطلوب",
  "الروس",
  "قبالة",
  "ابقوا",
  "حالته",
  "الرمز",
  "للوطن",
  "مكتبه",
  "وأكثر",
  "شجاعة",
  "نيكول",
  "كنيسة",
  "بيشوب",
  "اعرفه",
  "ماركو",
  "القرش",
  "قاتلة",
  "الطبي",
  "دراسة",
  "للقتل",
  "لنفسه",
  "الريح",
  "لجميع",
  "شركات",
  "القرص",
  "الجعة",
  "زاوية",
  "لنفعل",
  "والدى",
  "إستمر",
  "الفجر",
  "إخفاء",
  "ظننتك",
  "لأعلى",
  "البار",
  "قدميك",
  "لذيذة",
  "وأنها",
  "قرارك",
  "شخصيا",
  "بمعنى",
  "اشكرك",
  "جايمس",
  "فإنها",
  "جنسية",
  "بعملك",
  "اعادة",
  "بوابة",
  "قتلنا",
  "ذراعي",
  "وجودي",
  "بدوني",
  "درجات",
  "كايسي",
  "اتخاذ",
  "خمسون",
  "القرف",
  "هاريس",
  "اربعة",
  "عينيه",
  "إبقاء",
  "النحو",
  "نكتشف",
  "الجرس",
  "ممتعا",
  "حالتك",
  "ضحايا",
  "برايس",
  "نحتفل",
  "الغرض",
  "النجم",
  "ديلان",
  "حيثما",
  "موهبة",
  "وشأني",
  "خفيفة",
  "حوالى",
  "شخصين",
  "إنتبه",
  "اشخاص",
  "كابوس",
  "ويمكن",
  "الضحك",
  "العشب",
  "الذئب",
  "فرصتك",
  "لأقول",
  "بعملي",
  "جانبي",
  "السود",
  "قلبها",
  "النصر",
  "الرسم",
  "الحزب",
  "إصابة",
  "كارلا",
  "خريطة",
  "القوم",
  "اعتني",
  "متوقع",
  "حسابي",
  "خالية",
  "بينيت",
  "انتهي",
  "الآلي",
  "المضي",
  "عالقة",
  "لليلة",
  "ثلاثه",
  "اشتقت",
  "ملائم",
  "العصر",
  "للبيع",
  "إخراج",
  "القطة",
  "صناعة",
  "عودتك",
  "برلين",
  "انتقل",
  "غريبا",
  "شركاء",
  "طويله",
  "متوتر",
  "هائلة",
  "معقدة",
  "بأنكم",
  "شطيرة",
  "بنسبة",
  "بالحب",
  "قتلهم",
  "فستان",
  "قضايا",
  "صحيفة",
  "لغرفة",
  "وصلوا",
  "مصاصي",
  "زرقاء",
  "بحيرة",
  "إهانة",
  "التهم",
  "لايجب",
  "حولنا",
  "البرد",
  "جانيت",
  "صادقة",
  "مونرو",
  "ويندي",
  "العفو",
  "إزالة",
  "النفق",
  "جانبا",
  "عيناك",
  "مخلوق",
  "باوند",
  "كاذبة",
  "اختار",
  "جيدين",
  "مركبة",
  "الدرس",
  "إعطاء",
  "عرفته",
  "وأعلم",
  "إضافي",
  "فتيان",
  "بالمر",
  "اريده",
  "مفيدة",
  "الأهم",
  "قررنا",
  "الفلم",
  "حالها",
  "دوايت",
  "عميلة",
  "كوريا",
  "عندهم",
  "وأعرف",
  "إنهـا",
  "مزيفة",
  "متاكد",
  "فكرتك",
  "الصحة",
  "الذرة",
  "ممرضة",
  "عرفنا",
  "اتريد",
  "الأخت",
  "صغيري",
  "خلفنا",
  "فربما",
  "القمة",
  "ابنته",
  "فيغاس",
  "الكأس",
  "السلم",
  "نسيان",
  "فهناك",
  "الروك",
  "ليديا",
  "نستطع",
  "شعوري",
  "بيكيت",
  "متورط",
  "مرتاح",
  "مسبقا",
  "اقترب",
  "ويقول",
  "والكر",
  "محترم",
  "بجنون",
  "جنازة",
  "نريده",
  "لائحة",
  "عيناي",
  "البرج",
  "بشعور",
  "اتجاه",
  "رأيكم",
  "بطولة",
  "اخيرا",
  "جعلها",
  "جميلا",
  "دانيل",
  "وبدون",
  "عالمي",
  "بحياة",
  "دعينى",
  "شعرها",
  "ماندي",
  "كولين",
  "هاواي",
  "نولان",
  "رسمية",
  "مؤتمر",
  "وليام",
  "قراري",
  "جذابة",
  "الطرف",
  "رأيتم",
  "داريل",
  "رواية",
  "لحفلة",
  "الرعب",
  "لأفعل",
  "إختفى",
  "ضربات",
  "الفهم",
  "نموذج",
  "مضطرة",
  "الكهف",
  "حلوتي",
  "إعداد",
  "لقضاء",
  "نشاهد",
  "خائفا",
  "النفط",
  "إقامة",
  "رأيتي",
  "النمر",
  "الحوض",
  "افتحي",
  "إلغاء",
  "موسكو",
  "مصلحة",
  "جعلته",
  "احترس",
  "مررنا",
  "كلاوس",
  "لايهم",
  "فيونا",
  "إحساس",
  "هاورد",
  "انقاذ",
  "ذاكرة",
  "الكنز",
  "مرتبط",
  "شريحة",
  "هــذا",
  "العار",
  "ملازم",
  "حذائي",
  "اقوله",
  "ارتدي",
  "فريقي",
  "برؤية",
  "اتوقع",
  "خطوات",
  "صغيرا",
  "وحيدا",
  "كونور",
  "منخفض",
  "مختبر",
  "وتقول",
  "الأسف",
  "جيدون",
  "مثلهم",
  "بارعة",
  "لأسفل",
  "الـذي",
  "هدايا",
  "ديانا",
  "بدونه",
  "الصحي",
  "مارست",
  "الجوي",
  "كونوا",
  "المحك",
  "اهدئي",
  "بجميع",
  "خطورة",
  "فاتنة",
  "قادرا",
  "غرايس",
  "فريقك",
  "فعلتم",
  "علمنا",
  "قابلة",
  "إلاهي",
  "وقتنا",
  "كلارا",
  "خصوصا",
  "بنفسى",
  "قميصك",
  "نواجه",
  "لفعله",
  "للمال",
  "نناقش",
  "الركض",
  "الطبخ",
  "إبنتك",
  "راقصة",
  "شريرة",
  "ممثلة",
  "عادلة",
  "زالوا",
  "بورتر",
  "عديمة",
  "مجتمع",
  "مواقع",
  "مهووس",
  "حبيبى",
  "ذاتها",
  "علاقه",
  "نتأكد",
  "وضعوا",
  "اتفهم",
  "اراهن",
  "نستمر",
  "بشراء",
  "مرتفع",
  "القوي",
  "بريئة",
  "رومان",
  "وضعته",
  "معتوه",
  "افعلي",
  "ريتشي",
  "اشترى",
  "كايتي",
  "عقوبة",
  "حاسوب",
  "طلقات",
  "الابن",
  "ليزلي",
  "غرفته",
  "طالبة",
  "معسكر",
  "ميغان",
  "مبالغ",
  "بسرقة",
  "الوسط",
  "قصارى",
  "ولديه",
  "نيابة",
  "لشركة",
  "لحساب",
  "حصلوا",
  "اعطيك",
  "الضرب",
  "عثرنا",
  "عذراء",
  "مصنوع",
  "فيجاس",
  "اتخذت",
  "ميتشل",
  "خسرنا",
  "اللتي",
  "وفجأة",
  "الآمن",
  "اتركه",
  "مدهشة",
  "مفاجئ",
  "لتصبح",
  "كونها",
  "وحدنا",
  "خرجنا",
  "العيد",
  "الامن",
  "ذراعك",
  "لمنزل",
  "قضينا",
  "مقربة",
  "الخمس",
  "كتابك",
  "الكبد",
  "المثل",
  "بشرية",
  "بغرفة",
  "حالكم",
  "افترض",
  "كتابي",
  "بفعله",
  "ريتشل",
  "ثقيلة",
  "اعتاد",
  "نفسية",
  "شريكك",
  "الجدة",
  "برمته",
  "وثيقة",
  "ثنائي",
  "استعد",
  "الصبر",
  "طلبته",
  "كارمن",
  "جاسوس",
  "مخيفة",
  "مقدمة",
  "الصلب",
  "إتفاق",
  "برودي",
  "ماكنت",
  "إسبوع",
  "الخطط",
  "لتفعل",
  "الأسم",
  "هايلي",
  "فعليك",
  "العرش",
  "زوجين",
  "اسألك",
  "ادخلي",
  "العمة",
  "خدمات",
  "انسان",
  "خمسين",
  "زواجي",
  "غامضة",
  "صالحة",
  "اعطنى",
  "مكسور",
  "رجلنا",
  "ديبرا",
  "رخيصة",
  "ملكية",
  "ماكغي",
  "بقتله",
  "ملعون",
  "لأقوم",
  "مكانا",
  "دبليو",
  "الضعف",
  "النحل",
  "اخبره",
  "ننتقل",
  "فيروس",
  "القطب",
  "بأقصى",
  "ربمـا",
  "برافو",
  "عددهم",
  "بينكم",
  "غيرها",
  "لأنكم",
  "واجبي",
  "الفرص",
  "ممكنة",
  "قوموا",
  "وفاته",
  "نقودي",
  "ناجحة",
  "هولمز",
  "مايكي",
  "ثابتة",
  "الكوخ",
  "شخصان",
  "عودتي",
  "وشأنه",
  "لنكون",
  "الفشل",
  "نادرة",
  "جانبك",
  "موكلي",
  "دالاس",
  "جيرسي",
  "الذنب",
  "طيران",
  "شرائح",
  "لأعرف",
  "نمارس",
  "نجحنا",
  "خيانة",
  "والتى",
  "المدن",
  "اعمال",
  "محارب",
  "مضيعة",
  "بانني",
  "الأسد",
  "عادوا",
  "اولاد",
  "خلاله",
  "مزعجة",
  "مواطن",
  "وجميع",
  "لاننا",
  "منزعج",
  "فنسنت",
  "إيلاي",
  "الأصل",
  "هولاء",
  "لنقوم",
  "لفريق",
  "نتوقع",
  "ثمنها",
  "الحقل",
  "روبين",
  "السكن",
  "بالغة",
  "رجلين",
  "الصفر",
  "راشيل",
  "اولئك",
  "الشيك",
  "اصمتي",
  "الأذن",
  "جعلتك",
  "غرانت",
  "إسعاف",
  "هزيمة",
  "رساله",
  "كينزي",
  "لثلاث",
  "مستمر",
  "بغاية",
  "الطيب",
  "بوسعه",
  "مهمتك",
  "الحرس",
  "قولها",
  "مليار",
  "حذائك",
  "والاس",
  "نقترب",
  "بأمور",
  "خضراء",
  "الحفر",
  "والذى",
  "دافيد",
  "محددة",
  "الإذن",
  "القفص",
  "مكروه",
  "مضحكا",
  "الورم",
  "ورأيت",
  "غلطتك",
  "نهائي",
  "فإننا",
  "الطعم",
  "بيرسي",
  "اشارة",
  "شأنها",
  "وأنتي",
  "نريدك",
  "نتزوج",
  "ذهبية",
  "بأخرى",
  "حساسة",
  "رياضة",
  "العزف",
  "التقط",
  "رينيه",
  "اخبرت",
  "طوارئ",
  "رقائق",
  "خلايا",
  "إختفت",
  "للفوز",
  "فوستر",
  "شوارع",
  "حماقة",
  "اليهم",
  "مستاء",
  "وكأني",
  "الستة",
  "شيرلي",
  "اقترح",
  "بدأوا",
  "اشتري",
  "بانها",
  "بأكثر",
  "خلفية",
  "اقتله",
  "الجوع",
  "للرقص",
  "جسدها",
  "عرفوا",
  "ليستر",
  "عليكى",
  "معتاد",
  "مطرود",
  "السيئ",
  "الآمر",
  "اخرسي",
  "للأكل",
  "لفتاة",
  "الملح",
  "متوسط",
  "واسعة",
  "الفرن",
  "العنق",
  "قضيبك",
  "صريحة",
  "الفضل",
  "خارقة",
  "الطين",
  "المجد",
  "قرابة",
  "ايفان",
  "وبدأت",
  "جعلنا",
  "برامج",
  "معدات",
  "رهيبة",
  "إجلسي",
  "مألوف",
  "فاتني",
  "لتحصل",
  "غرامة",
  "روميو",
  "حكومة",
  "لسانك",
  "كلايد",
  "حذرين",
  "وظائف",
  "وحصلت",
  "وأيضا",
  "اجازة",
  "العصا",
  "عينات",
  "شرعية",
  "دخلنا",
  "القفل",
  "المجئ",
  "فطائر",
  "لكوني",
  "بأننى",
  "الكره",
  "إعتقد",
  "علاوة",
  "زائدة",
  "دراية",
  "فرصتي",
  "ارسلت",
  "الأمم",
  "انهما",
  "كينيث",
  "خدمتك",
  "ولادة",
  "مناطق",
  "شقراء",
  "رحلتك",
  "القبر",
  "مجاني",
  "رودني",
  "اللغز",
  "دافني",
  "للصيد",
  "بالدم",
  "جريدة",
  "وجوده",
  "زواجك",
  "لليوم",
  "قمامة",
  "طريقى",
  "قبيحة",
  "فتاتك",
  "عميقة",
  "وجدتك",
  "الشحن",
  "نتمنى",
  "عيادة",
  "ابنها",
  "مكتبة",
  "واقعة",
  "الأنف",
  "هوارد",
  "بقيمة",
  "حصلتي",
  "لوجان",
  "الطرد",
  "بوقتك",
  "منازل",
  "علماء",
  "وانتي",
  "نوعية",
  "الكشف",
  "وانظر",
  "لسرقة",
  "عظيما",
  "الحشد",
  "طوكيو",
  "رجلان",
  "الدمى",
  "قضيبي",
  "ظننته",
  "حلقات",
  "الكيس",
  "كافيا",
  "عارضة",
  "الماس",
  "ليقوم",
  "عاطفي",
  "السلك",
  "البرق",
  "حبيبة",
  "قنابل",
  "مملكة",
  "لطفاء",
  "كأننا",
  "علمني",
  "الأذى",
  "السهم",
  "حراسة",
  "عملاق",
  "الوشم",
  "لوحات",
  "مأساة",
  "الفتي",
  "نجتمع",
  "مندهش",
  "دماغك",
  "لتقوم",
  "لأحصل",
  "ثانيا",
  "إنتاج",
  "الفول",
  "مصادر",
  "دايان",
  "اعرفك",
  "ارسال",
  "راديو",
  "وعليك",
  "بحثنا",
  "لبناء",
  "ذاهبه",
  "موقعك",
  "مالية",
  "الفأر",
  "إكتشف",
  "الخدم",
  "خارجة",
  "بيتنا",
  "الثور",
  "لحضور",
  "دارين",
  "للحرب",
  "خادمة",
  "فاشلة",
  "هنــا",
  "اذهبى",
  "شيبرد",
  "ولديك",
  "طعامك",
  "بساطة",
  "شعبية",
  "التنس",
  "الجزر",
  "حالتي",
  "غاضبا",
  "ناهيك",
  "إنجاز",
  "بيرنز",
  "القدس",
  "الشاذ",
  "كثيره",
  "قدومك",
  "بقينا",
  "لديكي",
  "لكونك",
  "رغبتي",
  "إتجاه",
  "رياضي",
  "رجاله",
  "خائفه",
  "مسكين",
  "إيلين",
  "مهمتي",
  "الدرع",
  "فعلوه",
  "للطفل",
  "وأحضر",
  "فليكن",
  "إيذاء",
  "نقودك",
  "ليالي",
  "العرق",
  "ممتنة",
  "للقبض",
  "الجهد",
  "احبها",
  "بعمله",
  "غرامي",
  "عواقب",
  "استدر",
  "شقيقك",
  "برحلة",
  "منزلى",
  "موتها",
  "لنأخذ",
  "بصفتي",
  "نووية",
  "وحدات",
  "مزدوج",
  "مهندس",
  "مدمنة",
  "وأقول",
  "البصر",
  "موهوب",
  "للنظر",
  "للهرب",
  "عصبية",
  "رسميا",
  "ووجدت",
  "ويسكي",
  "وجبات",
  "رغبتك",
  "العهد",
  "لينيت",
  "زميلي",
  "حضورك",
  "للأرض",
  "الساق",
  "خاطيء",
  "جرانت",
  "فرنسي",
  "مريحة",
  "عشرات",
  "مستقر",
  "اياها",
  "رحلوا",
  "مجيئك",
  "الحرم",
  "بصحبة",
  "صورتك",
  "مقاعد",
  "ماهذه",
  "دافئة",
  "اهدأي",
  "بكامل",
  "غرباء",
  "دورية",
  "قبلها",
  "حكاية",
  "عائلي",
  "ديفيس",
  "مقاتل",
  "احببت",
  "التوت",
  "ميدان",
  "جانيس",
  "ميرفي",
  "حركات",
  "للشرب",
  "ميعاد",
  "ورائي",
  "الحار",
  "كامبل",
  "الخيل",
  "جديدا",
  "بدافع",
  "معدتي",
  "مديرة",
  "بسببه",
  "العون",
  "قميصي",
  "ببناء",
  "مسدسك",
  "رابطة",
  "مخادع",
  "عنيفة",
  "القاء",
  "وتريد",
  "دعوتك",
  "إقناع",
  "فريدة",
  "الرفض",
  "البطن",
  "بجولة",
  "فصيلة",
  "حسنـا",
  "القلم",
  "الذكي",
  "السنه",
  "بوريس",
  "قدرات",
  "كلينا",
  "الثقب",
  "بخيـر",
  "الباص",
  "غالية",
  "شيلبي",
  "واضحا",
  "إتصلي",
  "حوادث",
  "دماغي",
  "نيران",
  "واجهت",
  "صورته",
  "داخلك",
  "عمياء",
  "حقيقه",
  "احضار",
  "عسكري",
  "مقرفة",
  "شرطية",
  "وأصبح",
  "فقدته",
  "ويكون",
  "معلمة",
  "رهينة",
  "بلادي",
  "كلمتي",
  "وذهبت",
  "فائقة",
  "ليزبن",
  "إنطلق",
  "بنجاح",
  "وانها",
  "جوشوا",
  "لمكتب",
  "مثلكم",
  "حقيرة",
  "ذراعه",
  "محلية",
  "بخلاف",
  "احسنت",
  "متاعب",
  "هويته",
  "العظم",
  "ولكـن",
  "حسبما",
  "زملاء",
  "طلبوا",
  "لكانت",
  "الموز",
  "بليون",
  "لطيفه",
  "للشخص",
  "ليصبح",
  "إظهار",
  "نجدها",
  "كريهة",
  "بأسره",
  "مقياس",
  "اغنية",
  "بابلو",
  "بارنز",
  "لنحصل",
  "للابد",
  "فإنهم",
  "مقالة",
  "موقفك",
  "البعد",
  "لوغان",
  "مبارك",
  "معطفك",
  "لوجود",
  "ابننا",
  "العضو",
  "للبدء",
  "إزعاج",
  "طلبات",
  "النمو",
  "مظلمة",
  "السجل",
  "نتخلص",
  "ميناء",
  "وبينك",
  "وإنما",
  "حبيبك",
  "احدكم",
  "شيريل",
  "لعلمك",
  "مايكا",
  "مصباح",
  "جمعية",
  "امرأه",
  "بليلة",
  "ومهما",
  "اسئلة",
  "للقاء",
  "عضلات",
  "إميلي",
  "الإبن",
  "لأحضر",
  "هدفنا",
  "خاطىء",
  "انواع",
  "لانهم",
  "اللوح",
  "قبيلة",
  "طفلها",
  "العدم",
  "إبقوا",
  "عملهم",
  "العنب",
  "رحلتي",
  "الطول",
  "صداقة",
  "فإنني",
  "البول",
  "الريف",
  "إخلاء",
  "قنينة",
  "حثالة",
  "جالسة",
  "محبوب",
  "فرشاة",
  "الزيت",
  "السحب",
  "ايقاف",
  "اليأس",
  "بفرصة",
  "العشر",
  "إحتجت",
  "محتال",
  "ماركس",
  "نقاتل",
  "حظينا",
  "كانتا",
  "الطبق",
  "نساعد",
  "نفترض",
  "بمنزل",
  "باننا",
  "النبض",
  "موقعه",
  "بانكس",
  "مارسي",
  "كوننا",
  "دجاجة",
  "مراقب",
  "ممتلئ",
  "جاريد",
  "اليست",
  "طفلنا",
  "الأرز",
  "باربي",
  "قضيتك",
  "بايلي",
  "بيرني",
  "الجيب",
  "فضولي",
  "لقطات",
  "إطفاء",
  "جاسبر",
  "قدميه",
  "التاج",
  "منحرف",
  "للجيش",
  "لدخول",
  "حديثة",
  "بديلة",
  "مشابه",
  "بنوبة",
  "عودته",
  "اذهبا",
  "حياله",
  "ابنتى",
  "هواتف",
  "وأشعر",
  "حسابك",
  "السمو",
  "الدير",
  "بدرجة",
  "اسمحي",
  "الوعد",
  "بمرور",
  "شركتي",
  "زفافي",
  "العرب",
  "للعجب",
  "مسجلة",
  "عانيت",
  "رحلات",
  "شرابا",
  "وأفضل",
  "بالغد",
  "إيرين",
  "شانون",
  "واجبك",
  "خالتي",
  "جوائز",
  "واقفة",
  "ناضجة",
  "هويتك",
  "لعلاج",
  "شارون",
  "عرفتي",
  "مرهقة",
  "اطلقت",
  "بطيئة",
  "حقائب",
  "الفحم",
  "وقتما",
  "فروست",
  "إسقاط",
  "فقدوا",
  "هاتين",
  "فهمنا",
  "بمكتب",
  "لكنكم",
  "صوتها",
  "اجعله",
  "ليكسي",
  "ضربته",
  "مقبرة",
  "جاءوا",
  "مرتان",
  "موجات",
  "مؤلمة",
  "قالها",
  "كـانت",
  "الفيل",
  "وجدتم",
  "خططنا",
  "غريبه",
  "جوانا",
  "جوناس",
  "التـي",
  "فلابد",
  "وتذهب",
  "إدخال",
  "مواقف",
  "كتبته",
  "نشتري",
  "مزارع",
  "النسر",
  "لتقول",
  "اتمني",
  "عثروا",
  "القصه",
  "شريكة",
  "السعة",
  "قلقين",
  "دعاني",
  "معكما",
  "عندئذ",
  "بريان",
  "إنشاء",
  "لتعرف",
  "خلفها",
  "انخفض",
  "دعوتي",
  "فضيحة",
  "مرفوض",
  "الذعر",
  "معلقة",
  "قصيدة",
  "معناه",
  "فضائي",
  "جوديث",
  "قلقون",
  "وحاول",
  "دائمة",
  "كارلي",
  "مصطلح",
  "مسئول",
  "الحبس",
  "لتذهب",
  "هانحن",
  "شقيقة",
  "لقتله",
  "مشاهد",
  "علموا",
  "ليفعل",
  "وأردت",
  "الذكر",
  "الفصح",
  "وبشكل",
  "الجيل",
  "مراسم",
  "رفيقة",
  "إلهـي",
  "اراها",
  "للحفل",
  "صنعته",
  "وتكون",
  "قلتيه",
  "دعيها",
  "عاهره",
  "ماهية",
  "بطريق",
  "مثيرا",
  "مشوشة",
  "ايريك",
  "للجنس",
  "النسخ",
  "قصدته",
  "نستمع",
  "لنأمل",
  "قرأته",
  "شقيقي",
  "عالمك",
  "لنعرف",
  "الربع",
  "وايضا",
  "خيالي",
  "ديفون",
  "كاملا",
  "الالم",
  "الثوب",
  "عائدة",
  "بايدج",
  "إهدأي",
  "عائله",
  "اعتبر",
  "الابد",
  "بيعها",
  "الرمل",
  "انهاء",
  "مريعة",
  "عاملة",
  "فهمتك",
  "النمل",
  "بحدوث",
  "نتصرف",
  "الهام",
  "ظاهرة",
  "ليتني",
  "قذارة",
  "لنعود",
  "بمأمن",
  "الجاز",
  "دراما",
  "صحيـح",
  "اشاهد",
  "كيلسو",
  "إهدئي",
  "بدخول",
  "جنسيا",
  "اجراء",
  "احضري",
  "النمط",
  "زبائن",
  "مهتما",
  "اغادر",
  "نوعها",
  "العيب",
  "عندكم",
  "الجري",
  "قادمه",
  "خطيئة",
  "ضوضاء",
  "روبية",
  "ونذهب",
  "غرابة",
  "رقبتك",
  "نواصل",
  "مجانا",
  "متاحة",
  "قضيتي",
  "بفكرة",
  "نادلة",
  "كارلو",
  "اثبات",
  "للملك",
  "البدأ",
  "كصديق",
  "إفتحي",
  "الأدب",
  "عقلها",
  "لإنني",
  "الأفق",
  "مونتي",
  "جايمي",
  "اسمعى",
  "ضاجعت",
  "بأعلى",
  "حرفيا",
  "حيوية",
  "استحق",
  "الآيس",
  "الكيل",
  "الحرة",
  "جوناه",
  "النقي",
  "نجعله",
  "مارفن",
  "للضرب",
  "طلبنا",
  "بثلاث",
  "الرئة",
  "هادئا",
  "الدول",
  "محلات",
  "ميلان",
  "الوصف",
  "الذات",
  "ويريد",
  "عصيبة",
  "اصلاح",
  "صارمة",
  "رقيقة",
  "بيتسي",
  "كاتبة",
  "داخله",
  "مخطئا",
  "جلسات",
  "مسدود",
  "عناصر",
  "إيصال",
  "دخلوا",
  "لأيام",
  "لطبيب",
  "حاجتي",
  "لفتره",
  "نعــم",
  "الغبى",
  "قلادة",
  "القوه",
  "قبلنا",
  "بولين",
  "ثماني",
  "عيونك",
  "نختار",
  "انبطح",
  "مضطرب",
  "نورما",
  "الحلو",
  "متخصص",
  "بينها",
  "مهارة",
  "وتعلم",
  "وحشية",
  "وتقوم",
  "عقلية",
  "خرجوا",
  "الفضة",
  "الربح",
  "بخطوة",
  "لعبتك",
  "بريدي",
  "اليكم",
  "الصدق",
  "وضعية",
  "موثوق",
  "ابتسم",
  "صراحة",
  "مربية",
  "الثدي",
  "ضربني",
  "عاليا",
  "قبطان",
  "وواحد",
  "وأنني",
  "وسادة",
  "صفحات",
  "مقتنع",
  "للعرض",
  "وبذلك",
  "جاكوب",
  "مصممة",
  "لحادث",
  "انحاء",
  "ديزني",
  "إرادة",
  "إصدار",
  "محطات",
  "نمتلك",
  "about",
  "هربوا",
  "لغاية",
  "صاروخ",
  "اسماء",
  "برادي",
  "موعدك",
  "ريثما",
  "ممكنا",
  "نادني",
  "شركتك",
  "قدرتك",
  "الحبر",
  "لمزيد",
  "where",
  "لنلعب",
  "كتبها",
  "شاهدي",
  "رأيها",
  "وخلال",
  "كريمة",
  "اللبن",
  "عجلات",
  "ادارة",
  "الشاق",
  "فودكا",
  "لائقة",
  "كافيه",
  "إفساد",
  "عاجلا",
  "لهجوم",
  "خطتنا",
  "لواحد",
  "نايثن",
  "نأخذه",
  "وجدها",
  "فكروا",
  "الاحد",
  "صورتي",
  "ثمينة",
  "نوبات",
  "مالذى",
  "بائسة",
  "خبيرة",
  "وبهذا",
  "انقذت",
  "العاب",
  "واصلي",
  "ارتكب",
  "المقر",
  "لمركز",
  "دماغه",
  "مورفي",
  "جورجي",
  "نيثان",
  "مارشل",
  "نتعلم",
  "شقتها",
  "جدران",
  "الحمى",
  "فاكهة",
  "وأنـا",
  "كوينز",
  "القوس",
  "محدود",
  "البتة",
  "امراة",
  "إيمان",
  "ادركت",
  "عيناه",
  "كأنهم",
  "نهايه",
  "الأمة",
  "حشرات",
  "عشيقة",
  "ببالي",
  "مدينه",
  "طبيعى",
  "محرجة",
  "الرحم",
  "باليد",
  "محطمة",
  "مرتبة",
  "لقتلك",
  "دوريس",
  "واقعي",
  "مساءا",
  "إخترت",
  "وتعرف",
  "بقتلك",
  "دييغو",
  "بروكس",
  "معلمي",
  "اتوقف",
  "برونو",
  "ملكنا",
  "اللطف",
  "رقبته",
  "مماثل",
  "نصائح",
  "السمع",
  "انذار",
  "رامون",
  "بغداد",
  "القزم",
  "مرارا",
  "معذره",
  "ظهرها",
  "الأثر",
  "الدوق",
  "الضجة",
  "إمرأه",
  "كاندي",
  "نراها",
  "زفافك",
  "هينري",
  "شكلها",
  "نيوتن",
  "معطفي",
  "حذرتك",
  "إعتدت",
  "انظرا",
  "قضيبه",
  "عمليا",
  "جمهور",
  "محفظة",
  "دوران",
  "إبعاد",
  "اتمكن",
  "واريد",
  "امسكت",
  "would",
  "الكتف",
  "رايدر",
  "إنجاب",
  "السطو",
  "نبتعد",
  "موظفة",
  "قبضنا",
  "الفرح",
  "هانتر",
  "جاهزه",
  "وانتم",
  "نسميه",
  "ويتني",
  "إيملي",
  "اللقب",
  "لساعة",
  "شعبنا",
  "ثعبان",
  "صينية",
  "اموال",
  "مزرية",
  "رقبتي",
  "مزدحم",
  "كاسبر",
  "never",
  "منشفة",
  "وخاصة",
  "واذهب",
  "ماريو",
  "بفتاة",
  "ارادت",
  "ننفصل",
  "قليله",
  "ميغيل",
  "كايلي",
  "فيليس",
  "برأسك",
  "مذنبة",
  "نتذكر",
  "راهنت",
  "ضئيلة",
  "مثيره",
  "اغلاق",
  "الركن",
  "بقسوة",
  "وثائق",
  "والدن",
  "احساس",
  "وراءه",
  "مصاصة",
  "حقائق",
  "إفعلي",
  "دانتي",
  "حسـنا",
  "اتحاد",
  "محالة",
  "طفلين",
  "معالج",
  "إتخاذ",
  "بخدمة",
  "المئة",
  "بذالك",
  "رهائن",
  "قدرتي",
  "غيرنا",
  "ذهبتي",
  "الجزم",
  "الفيس",
  "وفكرت",
  "بالرب",
  "بالذي",
  "النقد",
  "غيورة",
  "كلمتك",
  "خاصته",
  "ناعمة",
  "مظهرك",
  "ميتين",
  "اوراق",
  "وهمية",
  "الخدع",
  "عليـه",
  "جثتها",
  "موديل",
  "مرغوب",
  "بناية",
  "بموجب",
  "الشاى",
  "وجدتي",
  "موراي",
  "القاع",
  "اعضاء",
  "اخذها",
  "بهـذا",
  "نائبة",
  "متحرك",
  "امامك",
  "الصلة",
  "لاشيئ",
  "رقاقة",
  "خذيها",
  "مهنتي",
  "مراهق",
  "شريكه",
  "نحتفظ",
  "انهضي",
  "ماهرة",
  "صادقا",
  "جليسة",
  "ظريفة",
  "الكرز",
  "فنجان",
  "زومبي",
  "رؤساء",
  "لديكى",
  "مخاطر",
  "برميل",
  "لتعلم",
  "بقضية",
  "مسدسي",
  "فهمتي",
  "نبضات",
  "رائـع",
  "مرحاض",
  "نقلها",
  "زراعة",
  "الخطه",
  "موظفي",
  "ليقول",
  "البري",
  "والله",
  "كلامه",
  "هواية",
  "لنشرب",
  "فرسان",
  "بعيده",
  "إعتني",
  "غاضبه",
  "لاننى",
  "دعابة",
  "مهجور",
  "الطير",
  "إشتقت",
  "حمالة",
  "راهول",
  "حدوثه",
  "للسفر",
  "عزباء",
  "انفجر",
  "هـناك",
  "منشأة",
  "جيكوب",
  "شيئان",
  "جلالة",
  "الحوت",
  "فئران",
  "بشركة",
  "خبرتي",
  "الصقر",
  "صالون",
  "لوهلة",
  "الندم",
  "مريضا",
  "حلاقة",
  "اهمية",
  "صديقا",
  "ذهابك",
  "مبكرة",
  "معرفه",
  "لحياة",
  "جماعي",
  "مولاى",
  "إشاعة",
  "اتخيل",
  "طعامي",
  "دارسي",
  "بواحد",
  "محمود",
  "مغرمة",
  "الفني",
  "وجيزة",
  "ننطلق",
  "نعتني",
  "لندخل",
  "بكثرة",
  "بصحتك",
  "طبيبي",
  "كالين",
  "كاميل",
  "القطن",
  "كامله",
  "لمنصب",
  "شائعة",
  "مسحوق",
  "ركبتي",
  "دعوته",
  "لتأتي",
  "جاهزا",
  "إكمال",
  "ليحدث",
  "عناية",
  "اماكن",
  "اقتحم",
  "لخدمة",
  "ثلاثي",
  "كيرتس",
  "واجهة",
  "ونقوم",
  "ثيابك",
  "صدرها",
  "نافيد",
  "عملوا",
  "المسح",
  "الدقة",
  "crane",
  "مخمور",
  "وأنهم",
  "لصديق",
  "مارلو",
  "بقتلي",
  "نائما",
  "بفترة",
  "افكار",
  "الخيط",
  "راندل",
  "للعلم",
  "اعلان",
  "عظيمه",
  "إحترس",
  "صناعي",
  "اللهب",
  "فضلات",
  "اساعد",
  "color",
  "نخبكم",
  "غاريت",
  "مفترض",
  "مؤقتة",
  "اقتلك",
  "لأصبح",
  "الحية",
  "معهما",
  "خبراء",
  "ماركة",
  "الوحل",
  "وحيده",
  "متصلة",
  "عليـك",
  "امتلك",
  "ميجان",
  "وبسبب",
  "كانون",
  "بطاطس",
  "دكستر",
  "احياء",
  "ربحنا",
  "البصل",
  "فلويد",
  "إعجاب",
  "جوارب",
  "حصلتم",
  "الاله",
  "بيدرو",
  "السبع",
  "نقانق",
  "صنعها",
  "ميرلن",
  "ذهابي",
  "الطلق",
  "لنقول",
  "داليا",
  "مغطاة",
  "بدوره",
  "ميسون",
  "نراقب",
  "فيينا",
  "ذكرني",
  "خداعي",
  "اسرعي",
  "جاسون",
  "الملل",
  "لأذهب",
  "صحفية",
  "اخراج",
  "مراكز",
  "بمقتل",
  "بموعد",
  "خروجك",
  "مسيرة",
  "إستدر",
  "بشـأن",
  "لنأكل",
  "برأيي",
  "لخمسة",
  "هديتي",
  "انصرف",
  "جانبه",
  "باسمك",
  "اصحاب",
  "بيانو",
  "القبة",
  "محاصر",
  "فعلتى",
  "حديثك",
  "خجولة",
  "بنظام",
  "خطيبي",
  "ضغينة",
  "بالغت",
  "للبشر",
  "محجوز",
  "نايجل",
  "لنرحل",
  "لرجال",
  "القرب",
  "الذيل",
  "كهدية",
  "ابريل",
  "اصدقك",
  "اتأكد",
  "الرعد",
  "عمليه",
  "فاسدة",
  "وبهذه",
  "مراسل",
  "شطائر",
  "الشغب",
  "الجمع",
  "افتقد",
  "بيتون",
  "طائفة",
  "قدموا",
  "ماضيك",
  "الصرف",
  "عديني",
  "حاملة",
  "لتجنب",
  "الجثه",
  "راحتك",
  "قائدة",
  "النذل",
  "بمبلغ",
  "للماء",
  "حظيرة",
  "وطلبت",
  "عملكم",
  "وظيفه",
  "لنجعل",
  "إبنته",
  "مخالف",
  "محمول",
  "نايلز",
  "راقبي",
  "زوجان",
  "اتركك",
  "نستعد",
  "اخرين",
  "جعلهم",
  "think",
  "واندا",
  "باستر",
  "الذهن",
  "مهنتك",
  "مقعدك",
  "للخوف",
  "عرضنا",
  "العمق",
  "غوثام",
  "وأنظر",
  "وعليه",
  "لافتة",
  "العطر",
  "مغرور",
  "للعبة",
  "الشرح",
  "وجدوه",
  "خلفهم",
  "قبلما",
  "مخفية",
  "مضاعف",
  "مقصدي",
  "قطعنا",
  "مطرقة",
  "لساني",
  "كيفما",
  "could",
  "قاتلت",
  "إخرسي",
  "بلحظة",
  "بلعبة",
  "قرروا",
  "رئيسه",
  "ارتدى",
  "جوليت",
  "روحها",
  "اقابل",
  "فتحها",
  "نفترق",
  "بمظهر",
  "جولات",
  "كفريق",
  "جاؤوا",
  "وأذهب",
  "إعصار",
  "عربات",
  "متجهة",
  "السور",
  "إلتقط",
  "قولوا",
  "ليحصل",
  "بروية",
  "الصنع",
  "ممتعه",
  "الجاف",
  "إقترب",
  "وشكرا",
  "وعدتك",
  "معدتك",
  "ويقوم",
  "ميجور",
  "ناديا",
  "البوب",
  "شاهدة",
  "السكة",
  "ضرائب",
  "كايلا",
  "حفلتي",
  "فيلما",
  "افلام",
  "حفيدي",
  "مسافر",
  "حملها",
  "إعترف",
  "كوستا",
  "ضرورة",
  "الفخر",
  "كدمات",
  "عنيدة",
  "إحتفظ",
  "نعتمد",
  "كوهين",
  "غوردن",
  "وتفعل",
  "رحبوا",
  "بالسن",
  "إستعد",
  "لوسيل",
  "قيمته",
  "طعمها",
  "بيتها",
  "راضية",
  "القبل",
  "بريتا",
  "عاجلة",
  "الامل",
  "إيران",
  "عدالة",
  "احترق",
  "محمية",
  "عالمة",
  "قبعتك",
  "الحصن",
  "باريت",
  "دونام",
  "نتركه",
  "إنتهي",
  "مايرز",
  "صفراء",
  "إيجار",
  "لتأخذ",
  "كتابه",
  "الفكر",
  "للمرء",
  "المؤن",
  "لاتكن",
  "مغنية",
  "ليخرج",
  "انكما",
  "نفقات",
  "وسمعت",
  "وأحصل",
  "بيديك",
  "موعدي",
  "منتجع",
  "لقائك",
  "قريبه",
  "مخبول",
  "اعطيه",
  "مارتا",
  "وأقوم",
  "البوق",
  "كاران",
  "ليذهب",
  "بقولك",
  "محامى",
  "خيارك",
  "نوافذ",
  "طازجة",
  "هيلدا",
  "فكرنا",
  "رقمها",
  "بطاطا",
  "بكوني",
  "لقتلي",
  "بسماع",
  "منديل",
  "اضربه",
  "جريمه",
  "علمية",
  "اسمعك",
  "وقاحة",
  "قبضوا",
  "فشلنا",
  "وصولك",
  "قنوات",
  "لعبنا",
  "جيسكا",
  "الفطر",
  "إنــه",
  "مورين",
  "ابناء",
  "عشيرة",
  "لنحضر",
  "اتحمل",
  "وتأتي",
  "المصل",
  "فهمتم",
  "مستقل",
  "فعالة",
  "غيابي",
  "نراكم",
  "بحركة",
  "الظرف",
  "عابرة",
  "حديثا",
  "ثرثرة",
  "نسينا",
  "مارني",
  "قبعتي",
  "بنطال",
  "ندعوه",
  "نقتله",
  "وكلما",
  "الفرس",
  "ملعقة",
  "موفقة",
  "وأخذت",
  "جارية",
  "اودري",
  "باكرا",
  "رايته",
  "بجهاز",
  "ولابد",
  "ابحثي",
  "اسلحة",
  "الضيق",
  "إدراك",
  "الشجر",
  "نتعرض",
  "ودعنا",
  "لوبيز",
  "زوجات",
  "ويوجد",
  "شارلى",
  "لورنس",
  "الخام",
  "مطلقة",
  "لرحلة",
  "عيوني",
  "بخيبة",
  "احذري",
  "نحارب",
  "مشروط",
  "نسيتي",
  "night",
  "مرتبك",
  "استرح",
  "فأنتم",
  "كمثال",
  "اثارة",
  "التبغ",
  "مكتبى",
  "دفعوا",
  "لجعلك",
  "صامتة",
  "صحراء",
  "موقفي",
  "اجعلك",
  "نتعرف",
  "منتهى",
  "عاصمة",
  "روسية",
  "البئر",
  "غاندي",
  "مرحبآ",
  "رجالى",
  "مرئية",
  "مدارس",
  "التام",
  "دفعنا",
  "مذكور",
  "منتظم",
  "انهار",
  "مصطفى",
  "روجرز",
  "شيئين",
  "ضائعة",
  "جيران",
  "ميرسي",
  "جاريت",
  "صوتية",
  "لنادي",
  "دعونى",
  "حضانة",
  "إعتاد",
  "نصفين",
  "لامعة",
  "ذخيرة",
  "بعينه",
  "خليلي",
  "مجلات",
  "ويسلي",
  "جيروم",
  "نتابع",
  "حاملا",
  "هاتان",
  "قتلوه",
  "جيثرو",
  "ليليث",
  "كرئيس",
  "واعدت",
  "رحيلك",
  "ضعفاء",
  "فاخرة",
  "بمركز",
  "لوريل",
  "جنيفر",
  "منضدة",
  "فهمها",
  "شخصيه",
  "ريلين",
  "هويتي",
  "ريغان",
  "مجروح",
  "لجعله",
  "وفعلت",
  "لعبتي",
  "الرهن",
  "مبنية",
  "اصعدي",
  "امامي",
  "كرستي",
  "لافون",
  "اقدام",
  "براءة",
  "حفلتك",
  "متنها",
  "بلايك",
  "محققة",
  "مانوع",
  "إشعار",
  "إتحاد",
  "مضطرا",
  "لتفقد",
  "الكوب",
  "بالصف",
  "حكومي",
  "هايدي",
  "مايجب",
  "قريبي",
  "دينية",
  "اتركي",
  "كفتاة",
  "الفرد",
  "مدريد",
  "راجعت",
  "heart",
  "الجوز",
  "لنفسى",
  "منتزه",
  "همفري",
  "ماحصل",
  "لأفضل",
  "مكـان",
  "بضربة",
  "حميمة",
  "إتمام",
  "ارفعي",
  "دعارة",
  "مؤمنة",
  "بأمره",
  "نتأخر",
  "بدوام",
  "قطرات",
  "بلدنا",
  "حتفهم",
  "نتحقق",
  "وصلني",
  "مرضاي",
  "راهبة",
  "انتشر",
  "نينجا",
  "للأذى",
  "باريش",
  "الجرو",
  "الغوص",
  "لقاؤك",
  "قبلني",
  "مزورة",
  "نظرنا",
  "وزوجة",
  "اعطاء",
  "كايلب",
  "ونخرج",
  "معطلة",
  "بأسفل",
  "بفريق",
  "كميات",
  "بمهمة",
  "دلائل",
  "احضره",
  "الخوخ",
  "وشعرت",
  "لونها",
  "اسلوب",
  "متقدم",
  "واصلت",
  "نملكه",
  "اضافة",
  "صياغة",
  "كافري",
  "بسلاح",
  "الشمع",
  "افسدت",
  "بحفلة",
  "خارجي",
  "اتباع",
  "انهيت",
  "ارجعي",
  "اخفاء",
  "حمولة",
  "نتقدم",
  "لتعيش",
  "الشيخ",
  "مريضه",
  "القرض",
  "خيرية",
  "قضيته",
  "ورقية",
  "حسننا",
  "لاأحد",
  "محبطة",
  "مراحل",
  "طائلة",
  "اندرو",
  "النية",
  "مخفوق",
  "لبرهة",
  "الكيك",
  "الحيل",
  "قدمها",
  "نحونا",
  "ثقافة",
  "برهان",
  "شاركت",
  "حولهم",
  "صدقنى",
  "اهربي",
  "إنتقل",
  "اوقات",
  "لأطلب",
  "فريقه",
  "اراكم",
  "وتعود",
  "الفقر",
  "اردتي",
  "نستحق",
  "مضادة",
  "كيونغ",
  "لتوها",
  "وينتر",
  "جمعنا",
  "وقعنا",
  "اوامر",
  "بانهم",
  "ماريس",
  "عماذا",
  "هجمات",
  "رفاقي",
  "الغرق",
  "زائفة",
  "بوصات",
  "لقــد",
  "دورات",
  "دوائر",
  "مرعبة",
  "شميدت",
  "وصلتك",
  "كلاين",
  "ميتون",
  "الاكل",
  "باقية",
  "خسائر",
  "واحصل",
  "موفقا",
  "بكونك",
  "قرصان",
  "الريث",
  "قديمه",
  "افراد",
  "قراره",
  "نعومي",
  "عريضة",
  "وارين",
  "هاردي",
  "اميال",
  "مخاوف",
  "والحب",
  "طفلان",
  "اعوام",
  "فقيرة",
  "كفايه",
  "الدخل",
  "التعب",
  "دعاية",
  "اختطف",
  "هاسكل",
  "حاجتك",
  "الحقن",
  "بخمسة",
  "غريزة",
  "الألف",
  "للجنة",
  "فعليا",
  "طبقات",
  "قوتها",
  "راؤول",
  "قدرته",
  "مرضية",
  "الشمل",
  "ربيكا",
  "اسمعا",
  "متهور",
  "جلبته",
  "ديفيا",
  "مكاتب",
  "انصتي",
  "بلانت",
  "عقليا",
  "ونعود",
  "خلاصة",
  "إيفري",
  "مريضي",
  "والدا",
  "برغبة",
  "اعتنى",
  "علاجه",
  "رفاقك",
  "بلهاء",
  "فوضوي",
  "الختم",
  "راتبي",
  "صوابك",
  "إنكار",
  "لكونه",
  "مساؤك",
  "حادثا",
  "مؤكدة",
  "دزينة",
  "إعدام",
  "الحرق",
  "وأخرج",
  "السلع",
  "لأمرك",
  "مكانة",
  "الثأر",
  "منفصل",
  "اعطيت",
  "إذهبى",
  "ليلية",
  "ضريبة",
  "جهدنا",
  "منتهي",
  "وعدنا",
  "ببالك",
  "إشعال",
  "بوجهي",
  "بالرد",
  "نحافظ",
  "راحلة",
  "جنونه",
  "فخورا",
  "اغلقت",
  "الراب",
  "استرخ",
  "مفعول",
  "جونسن",
  "الغزو",
  "زلزال",
  "قضائي",
  "نقوله",
  "خليلة",
  "طبيبا",
  "لكلمة",
  "ميلين",
  "عاجزة",
  "برأسي",
  "بمزاج",
  "الصغر",
  "لأربع",
  "صفقات",
  "وأننا",
  "لموقع",
  "فقراء",
  "مخطىء",
  "حاوية",
  "عضوية",
  "فوقها",
  "وأخبر",
  "اخترع",
  "الخوض",
  "مديري",
  "اصابة",
  "ايثان",
  "فريزر",
  "البني",
  "جايلز",
  "شقيقه",
  "إشترى",
  "بريجز",
  "وسيتم",
  "الغني",
  "حديثي",
  "مطاعم",
  "ديلون",
  "نعطيه",
  "جينجر",
  "فكرته",
  "مذاقه",
  "وزنها",
  "مطيعة",
  "جوانب",
  "لقوله",
  "نوريس",
  "اطلبي",
  "إعطني",
  "شيفون",
  "ليأخذ",
  "فلينت",
  "راقية",
  "هاربة",
  "وتصبح",
  "مسلية",
  "فأكثر",
  "مصيرك",
  "cause",
  "مغادر",
  "إختار",
  "اسنان",
  "امارس",
  "اعتمد",
  "ويذهب",
  "نتسكع",
  "كــان",
  "لتبقى",
  "ارتفع",
  "عاشوا",
  "ملحوظ",
  "ضعيها",
  "وضعهم",
  "صاخبة",
  "نقابل",
  "خاطئا",
  "بمسدس",
  "باولو",
  "مقعدي",
  "كاليب",
  "مؤهلة",
  "بدعوة",
  "وعدني",
  "إيميت",
  "لعينه",
  "جمجمة",
  "مقدسة",
  "لتعود",
  "فيرجل",
  "التقى",
  "طلبتي",
  "معدني",
  "لئيمة",
  "فاقدة",
  "وتذكر",
  "قياسي",
  "منحدر",
  "مدبرة",
  "متخلف",
  "وقتكم",
  "لنلقي",
  "عينان",
  "لمجلس",
  "حدودك",
  "ودعني",
  "خروجي",
  "الكلى",
  "وتحدث",
  "ليأتي",
  "دليلا",
  "معجون",
  "موقعي",
  "وصوله",
  "خاطرت",
  "مائدة",
  "وتركت",
  "نرتدي",
  "قصتنا",
  "محاسب",
  "قدومي",
  "بفضلك",
  "لذالك",
  "ذبابة",
  "ديمون",
  "إسحاق",
  "مقنعة",
  "شعرنا",
  "مسكون",
  "مضمون",
  "جسمها",
  "لهـذا",
  "صنعوا",
  "السجق",
  "جعلنى",
  "متوفر",
  "مصدوم",
  "ضربها",
  "الظلم",
  "البال",
  "واحضر",
  "كريسي",
  "مقلية",
  "شركته",
  "الفخذ",
  "الحصة",
  "شرطيا",
  "معجبه",
  "مجموع",
  "مادام",
  "بعقلك",
  "بعشرة",
  "منظور",
  "مدنية",
  "إرضاء",
  "هارون",
  "انفصل",
  "إبنها",
  "قاتله",
  "ووضعت",
  "رفيقك",
  "فراشة",
  "قتلتك",
  "مختصر",
  "مالون",
  "نسافر",
  "متروك",
  "متنزه",
  "الفئة",
  "عقدنا",
  "وظننت",
  "بريدك",
  "قدماي",
  "دخولك",
  "القيم",
  "نسيته",
  "اتينا",
  "جهزوا",
  "مطابق",
  "العلن",
  "لنجرب",
  "غرفتى",
  "نادرا",
  "بارون",
  "باسمه",
  "ثالثة",
  "كدليل",
  "زميلك",
  "اجزاء",
  "بنزين",
  "عطشان",
  "إحداث",
  "إدمان",
  "مسلحة",
  "عاطفة",
  "ثيابي",
  "برودة",
  "اليلة",
  "الجلل",
  "افتحه",
  "لتجعل",
  "طيبون",
  "لضمان",
  "ويصبح",
  "فتحات",
  "world",
  "اخطأت",
  "ليسـت",
  "الأجر",
  "طريقا",
  "إضاعة",
  "كارفر",
  "فوائد",
  "عارمة",
  "واثقا",
  "قاسيا",
  "بقضاء",
  "ارحلي",
  "للحظه",
  "نقدية",
  "واحـد",
  "البقع",
  "الاذن",
  "خياري",
  "ضمنهم",
  "كاريف",
  "اغلقي",
  "مذبحة",
  "مستوي",
  "مقولة",
  "حذائه",
  "للمرح",
  "كانـت",
  "ثوانى",
  "مترجم",
  "مكلفة",
  "عميقا",
  "إنبطح",
  "ورائك",
  "اجابة",
  "كـذلك",
  "مهتمه",
  "هديتك",
  "قهوتك",
  "جسدية",
  "للقلب",
  "الخصم",
  "إدغار",
  "صباحك",
  "صنعنا",
  "نادية",
  "البطة",
  "وردية",
  "إذهبا",
  "بطوله",
  "مخطيء",
  "الورد",
  "حدودي",
  "ملاكي",
  "مروعة",
  "جاكسن",
  "اليوت",
  "اتوسل",
  "مدمرة",
  "لرئيس",
  "نعاني",
  "مربوط",
  "الحمر",
  "النشر",
  "متعدد",
  "ويليس",
  "وماهو",
  "امسكي",
  "ويلاه",
  "النزل",
  "نتلقى",
  "قائلا",
  "دفعها",
  "نماذج",
  "عرفتك",
  "شراكة",
  "الحرف",
  "السار",
  "اتطلع",
  "الفرع",
  "الأسر",
  "رديئة",
  "لتلقي",
  "نتطلع",
  "ضوئية",
  "رغبته",
  "موقوف",
  "انزلي",
  "لاشىء",
  "بناتي",
  "متاجر",
  "ذاهبا",
  "للوعي",
  "بسكين",
  "كفاءة",
  "حيازة",
  "حاولى",
  "بسؤال",
  "فراشك",
  "جديرة",
  "طفلتك",
  "هادىء",
  "بمعدل",
  "انسحب",
  "اردنا",
  "مسمار",
  "ويستن",
  "شارتك",
  "عموما",
  "الثوم",
  "نحوها",
  "ليعمل",
  "استاذ",
  "خاطفة",
  "كواكب",
  "ارقام",
  "قهوتي",
  "قوارب",
  "عادات",
  "كلاير",
  "اربعه",
  "مظهري",
  "اترين",
  "الفأس",
  "اخذوا",
  "طبيبك",
  "فراشي",
  "للكذب",
  "جريئة",
  "إبادة",
  "برقية",
  "نتدرب",
  "عرضها",
  "اينما",
  "وخمسة",
  "كيمبل",
  "مايلي",
  "جلسنا",
  "الجار",
  "السكك",
  "لعملك",
  "شاملة",
  "قالوه",
  "اسأله",
  "شرابك",
  "اشربي",
  "محافظ",
  "صوابي",
  "إرجاع",
  "مهمته",
  "الطهي",
  "فنانة",
  "وأعود",
  "عينها",
  "العطل",
  "باهظة",
  "لشهور",
  "للباب",
  "نجونا",
  "فتـاة",
  "منافس",
  "إنهضي",
  "البوم",
  "اضافي",
  "وتحصل",
  "هوليس",
  "وطننا",
  "إخوتي",
  "مايلو",
  "لعالم",
  "الجرذ",
  "عاليه",
  "جبناء",
  "لأشهر",
  "وتأكد",
  "فورست",
  "مقتله",
  "لتبدأ",
  "الجمل",
  "صيحات",
  "دخوله",
  "دايزي",
  "لوجهك",
  "وقامت",
  "محاطة",
  "رغبات",
  "بموقع",
  "واقفا",
  "وضعني",
  "هولدن",
  "مزيدا",
  "كلمته",
  "مبارة",
  "إطلاع",
  "حسابه",
  "صائبة",
  "نتخطى",
  "دييجو",
  "جوهرة",
  "مفيدا",
  "لإنها",
  "العبء",
  "عرضوا",
  "هيــا",
  "بوينت",
  "roman",
  "هانسن",
  "دموية",
  "للدفع",
  "ماسون",
  "الشوط",
  "وتأخذ",
  "طاقتك",
  "مخلصة",
  "جهازي",
  "مكانى",
  "لفرقة",
  "وايلد",
  "الحلق",
  "حالنا",
  "اجلها",
  "خروجه",
  "مسيحي",
  "مابين",
  "القمح",
  "مشعوذ",
  "وأخرى",
  "شرائط",
  "قاتلا",
  "بضاعة",
  "شفرات",
  "مجيئي",
  "مسيطر",
  "هاجمت",
  "مميتة",
  "لنبحث",
  "جراند",
  "للاسف",
  "بشراب",
  "ابقاء",
  "رفضوا",
  "الكعب",
  "لأفكر",
  "مبادئ",
  "وصولي",
  "باردا",
  "جعلوا",
  "ونحصل",
  "للشعب",
  "خدعتك",
  "بثبات",
  "حصاني",
  "صافية",
  "إنظرى",
  "شكـرا",
  "كنساس",
  "ملتزم",
  "موجهة",
  "هندية",
  "غيابك",
  "وأكون",
  "بحوزة",
  "بوروز",
  "غابات",
  "محكوم",
  "مسدسه",
  "متوحش",
  "وتبدأ",
  "انتاج",
  "مقيدة",
  "نضعها",
  "مليئه",
  "منحني",
  "خاصتى",
  "رمادي",
  "ايرين",
  "للضوء",
  "اجهزة",
  "بحادث",
  "ماضيه",
  "بأداء",
  "هارلي",
  "غادري",
  "منطقه",
  "كلفني",
  "نخاطر",
  "بقطعة",
  "الهرم",
  "بسيطه",
  "قيامك",
  "مكتمل",
  "مبتدئ",
  "حصولك",
  "إيرني",
  "العفن",
  "خوسيه",
  "مدفون",
  "مرافق",
  "مزاجي",
  "مكتئب",
  "لننهي",
  "موعدا",
  "بجديه",
  "عازبة",
  "لعطلة",
  "النصل",
  "فلنرى",
  "الوهم",
  "اعداد",
  "اللمس",
  "لاكون",
  "لركوب",
  "موكلك",
  "ادفعي",
  "روهان",
  "باسمي",
  "قمصان",
  "منشار",
  "فلنقل",
  "دنكان",
  "قميصه",
  "ولدنا",
  "السعي",
  "بيديه",
  "إساءة",
  "الصحن",
  "ذاتية",
  "العذر",
  "معدته",
  "غيرهم",
  "قوتنا",
  "محقين",
  "نعتذر",
  "روبوت",
  "مكيدة",
  "مصيري",
  "إبننا",
  "ورجال",
  "براحة",
  "ضيفنا",
  "نباتي",
  "اوافق",
  "الفلك",
  "حربية",
  "دورثي",
  "مثالى",
  "حواجز",
  "وتوقف",
  "لنرقص",
  "كوبرا",
  "جامعي",
  "ايلين",
  "راتبك",
  "عنصري",
  "غيروا",
  "للأمن",
  "روكسي",
  "مسدسا",
  "قدمنا",
  "دورنا",
  "لاعبي",
  "شأننا",
  "منزلا",
  "مخزون",
  "إلهام",
  "فراني",
  "جنونا",
  "الغول",
  "لتخرج",
  "طوابق",
  "حضوري",
  "going",
  "خطابك",
  "قائمه",
  "للمشي",
  "مهمات",
  "القصف",
  "كاتيا",
  "اصطدم",
  "للدعم",
  "روهيت",
  "البوح",
  "queen",
  "مغسلة",
  "نصيبي",
  "كلايف",
  "مورتي",
  "اصوات",
  "لبيتك",
  "شنيعة",
  "حاسمة",
  "شظايا",
  "اركبي",
  "للألم",
  "عنكما",
  "لرأسك",
  "ليقتل",
  "القرى",
  "لنظام",
  "روسكو",
  "لديـك",
  "مئوية",
  "بأزمة",
  "صريحا",
  "وأطلق",
  "بإنها",
  "الملأ",
  "قاربت",
  "بريغز",
  "قادره",
  "وارنر",
  "الشغل",
  "جلبنا",
  "لحالة",
  "مصالح",
  "ليحضر",
  "فيلكس",
  "حددنا",
  "خرافة",
  "الشقق",
  "فوقنا",
  "مدرسه",
  "السطر",
  "لسانه",
  "طولها",
  "مجهود",
  "الصدع",
  "محبوس",
  "فترات",
  "شانيل",
  "جهازك",
  "لنعمل",
  "اتصرف",
  "بأحسن",
  "كتيبة",
  "ضميرك",
  "طبيبه",
  "درايك",
  "لفيلم",
  "قضبان",
  "اكرهك",
  "عنيته",
  "الخلق",
  "طائره",
  "النرد",
  "نوفاك",
  "للمضي",
  "صيانة",
  "ليعرف",
  "حزينا",
  "شيكات",
  "لتحمل",
  "الثقه",
  "نخبره",
  "الحاد",
  "قصيره",
  "الأخذ",
  "بنادق",
  "لديهن",
  "موتهم",
  "لتعمل",
  "لزوجي",
  "اتزوج",
  "كالفن",
  "إنـها",
  "نقابة",
  "قطعوا",
  "احترم",
  "اركضي",
  "لعمله",
  "بوجهك",
  "ليعود",
  "إبنتى",
  "نعالج",
  "طابور",
  "مأمور",
  "وحدهم",
  "نشكرك",
  "عنهما",
  "شيلدن",
  "للسطح",
  "اصابع",
  "ماتيو",
  "الذوق",
  "ظهيرة",
  "اردته",
  "مؤثرة",
  "معارك",
  "وعدته",
  "مالدي",
  "عميلي",
  "مارين",
  "دفعته",
  "الغاء",
  "اعنيه",
  "نعلمه",
  "زوايا",
  "طاقمي",
  "حصريا",
  "دلالة",
  "عادلا",
  "فاولر",
  "بإحدى",
  "مهمين",
  "مئتان",
  "مالنا",
  "ومكان",
  "مهنية",
  "حادثه",
  "لديـه",
  "بإسمك",
  "فيلمك",
  "وصلتم",
  "مروان",
  "اخترق",
  "نصيبك",
  "انتحر",
  "واعرف",
  "متعبه",
  "إصبعي",
  "دولية",
  "اسعاف",
  "واعلم",
  "ثلاجة",
  "وتخرج",
  "بشكـل",
  "عليهن",
  "غارقة",
  "الغجر",
  "اشترت",
  "كأسين",
  "ضربتك",
  "شاهده",
  "الفضي",
  "رايلى",
  "جائعا",
  "الهوس",
  "منعطف",
  "الدرب",
  "بفندق",
  "وخرجت",
  "متشوق",
  "روتين",
  "لستما",
  "نسيطر",
  "رحلته",
  "مصرفي",
  "طاقتي",
  "إفطار",
  "خشبية",
  "امانع",
  "مخالب",
  "هادئه",
  "للسكن",
  "انقلب",
  "الريش",
  "خيارا",
  "ذكرته",
  "غواصة",
  "حجمها",
  "ذهبتم",
  "متاخر",
  "مقطوع",
  "حضارة",
  "هويات",
  "اخرجا",
  "للقول",
  "إصبعك",
  "ويعمل",
  "جوردي",
  "إطعام",
  "قبعات",
  "هايدن",
  "ليروي",
  "ونريد",
  "شمسية",
  "لعشرة",
  "شفتيك",
  "إيستر",
  "رويدك",
  "مدربة",
  "لأمره",
  "مارجو",
  "نتحمل",
  "مديرك",
  "بقائي",
  "ماستر",
  "egypt",
  "خاسرة",
  "واضحه",
  "رفيعة",
  "قذيفة",
  "وتعمل",
  "وسعنا",
  "شهيرة",
  "حاضرة",
  "لأداء",
  "حدائق",
  "مخصصة",
  "اتهام",
  "وقتلت",
  "منعزل",
  "مصيبة",
  "العجل",
  "وقررت",
  "كراين",
  "الميل",
  "الجاد",
  "لكثير",
  "واعية",
  "نومها",
  "بنظرة",
  "لـقـد",
  "زواجه",
  "النبي",
  "صابون",
  "every",
  "جالسا",
  "نادين",
  "ليلتي",
  "اللحن",
  "نبيلة",
  "مفصول",
  "فقاعة",
  "ميرسر",
  "فرودو",
  "وأطلب",
  "الزرع",
  "بشارع",
  "كاحلي",
  "اوووه",
  "اهانة",
  "مغلقا",
  "ونبدأ",
  "القصد",
  "مارتى",
  "لجهاز",
  "معضلة",
  "الحصص",
  "دايفس",
  "مذعور",
  "مشرقة",
  "مندوب",
  "اخبري",
  "لحالك",
  "التحف",
  "الآله",
  "جايوس",
  "ليموت",
  "رائحه",
  "اخذنا",
  "مارغو",
  "هوجمت",
  "الخلل",
  "كفالة",
  "لموعد",
  "ahmed",
  "شارما",
  "لاباس",
  "بعيني",
  "خذلتك",
  "حجارة",
  "بعطلة",
  "مدركة",
  "لمسها",
  "علمته",
  "مصيره",
  "ليجعل",
  "واخرج",
  "انظرو",
  "إمراة",
  "قوائم",
  "اسرار",
  "خرائط",
  "حاربت",
  "الصبى",
  "بلاين",
  "ليكتر",
  "رافعة",
  "عليني",
  "وجميل",
  "عوالم",
  "الحسن",
  "ليبقى",
  "إرتدي",
  "إحياء",
  "قصتها",
  "غائبة",
  "حضرتك",
  "محلول",
  "للشمس",
  "بدينة",
  "صوابه",
  "ويخرج",
  "غدائي",
  "إدانة",
  "ارتاح",
  "شارلز",
  "خطيبك",
  "ننتهى",
  "علامه",
  "فكـرة",
  "ولأجل",
  "لنبقى",
  "البؤس",
  "مولود",
  "صرخات",
  "عنقها",
  "مقاطع",
  "منهكة",
  "لاتقل",
  "دمروا",
  "الحقد",
  "افهمك",
  "عامان",
  "الكسر",
  "الخفي",
  "مواهب",
  "طعامه",
  "بصديق",
  "العلب",
  "المسن",
  "متعمد",
  "جزئية",
  "علمتك",
  "فائده",
  "منشغل",
  "شاغرة",
  "اسالك",
  "رحيلي",
  "متبرع",
  "حورية",
  "وراءك",
  "نسألك",
  "بدوار",
  "طفيفة",
  "للكشف",
  "لاجلك",
  "دورين",
  "بفيلم",
  "حملتك",
  "لأعيش",
  "اعاني",
  "فتاتى",
  "للذين",
  "لداخل",
  "لحدوث",
  "زميلة",
  "إهداء",
  "اعطنا",
  "دانكن",
  "مشكوك",
  "متشرد",
  "ضميري",
  "دردشة",
  "كالتي",
  "رايتك",
  "فحسـب",
  "عاتقي",
  "ورؤية",
  "وثلاث",
  "حينئذ",
  "متدرب",
  "فهمته",
  "للضغط",
  "الرمح",
  "للعقل",
  "مقبوض",
  "بربكم",
  "اعطها",
  "فرويد",
  "خياطة",
  "مادمت",
  "مصانع",
  "قدامى",
  "عدونا",
  "شمالا",
  "هاتفى",
  "طماطم",
  "فرعون",
  "شرابي",
  "ازداد",
  "الصوف",
  "حملته",
  "الرؤى",
  "لأبعد",
  "الكود",
  "معتدل",
  "النكت",
  "بحكمة",
  "اعجبك",
  "مخبأة",
  "نحتسي",
  "لايسي",
  "مملوء",
  "حافظة",
  "علمتي",
  "هاينز",
  "وفتاة",
  "هانـك",
  "حضروا",
  "الخجل",
  "لأعود",
  "كولير",
  "الجنه",
  "التلة",
  "لشراب",
  "الحطب",
  "بأنكي",
  "ويندل",
  "منتظر",
  "وحياة",
  "منتشي",
  "مباني",
  "منحنا",
  "اعتقل",
  "بقائك",
  "برمجة",
  "وليمة",
  "إذاعة",
  "صحيحه",
  "نـعـم",
  "غيابه",
  "حكيمة",
  "ولاكن",
  "الأبن",
  "نخرجه",
  "مويرا",
  "لأخذك",
  "الحمم",
  "ويبدأ",
  "كرامة",
  "العصب",
  "انقطع",
  "منصبه",
  "دعيهم",
  "جمالك",
  "برأسه",
  "بذكاء",
  "اضغطي",
  "للقفز",
  "بصفتك",
  "دونات",
  "غريبي",
  "محتجز",
  "للقسم",
  "بكلتا",
  "ركبتك",
  "الاهي",
  "إمساك",
  "للبعض",
  "خبرتك",
  "ملكتي",
  "وقمنا",
  "اعترض",
  "نتولى",
  "موارد",
  "مدافع",
  "قدرنا",
  "موطني",
  "نوقفه",
  "هربنا",
  "مبتذل",
  "مطالب",
  "بعالم",
  "رسمها",
  "لينور",
  "بأمرك",
  "منحتك",
  "بلاير",
  "ازعاج",
  "ونقول",
  "بقربي",
  "الفزع",
  "حصانك",
  "العبد",
  "منطقى",
  "خيالك",
  "فيلمي",
  "قاضية",
  "الجشع",
  "اخذته",
  "إتخذت",
  "بمسرح",
  "مشجعة",
  "فاسقة",
  "باليه",
  "وشاهد",
  "برجال",
  "وفريق",
  "شعوره",
  "وأعدك",
  "مريضك",
  "حصولي",
  "دفعني",
  "فيهـا",
  "اطلاع",
  "بضربه",
  "شرارة",
  "مشتاق",
  "قوتهم",
  "إدجار",
  "حرائق",
  "حقوقي",
  "لاعبة",
  "التقي",
  "الطوب",
  "الزحف",
  "للصوت",
  "روندا",
  "النصب",
  "إلهاء",
  "موقعة",
  "اجلبي",
  "إخرجي",
  "ظهروا",
  "موعده",
  "حصانة",
  "لأعمل",
  "لتموت",
  "الصخر",
  "بينجي",
  "جرعات",
  "قدرها",
  "خرقاء",
  "لعلها",
  "طباعة",
  "بلازا",
  "انظـر",
  "وصفها",
  "ناظري",
  "قاربي",
  "ايمكن",
  "مذهلا",
  "غذائي",
  "لتغير",
  "ابدأي",
  "للعلن",
  "صادفت",
  "جلدية",
  "لمطعم",
  "للآخر",
  "ورائه",
  "عبرنا",
  "إعطنى",
  "جيوبك",
  "دفاعي",
  "بيرتا",
  "دايلي",
  "نلتقط",
  "حافظت",
  "معارف",
  "وطنية",
  "ريبلي",
  "وزوجي",
  "اجلنا",
  "ولسنا",
  "ازالة",
  "النطق",
  "جنوبا",
  "نخبرك",
  "لعشاء",
  "لوشيس",
  "مذهله",
  "لقائي",
  "مكشوف",
  "خطيرا",
  "بولوك",
  "ويعرف",
  "شبابي",
  "فريسة",
  "لأبحث",
  "مشفرة",
  "لقرون",
  "ضرورى",
  "بكونه",
  "بارلو",
  "شقتنا",
  "وعرفت",
  "لينزي",
  "لأجعل",
  "الإبر",
  "رفقاء",
  "كوليت",
  "رؤيتى",
  "نظامي",
  "دخولي",
  "بفارق",
  "فكتور",
  "عميلك",
  "نأخذك",
  "هاديء",
  "حصرية",
  "احذرك",
  "الغطس",
  "إجعله",
  "قذائف",
  "رأيتى",
  "طلبها",
  "loser",
  "ننتمي",
  "دونها",
  "ويعود",
  "ميندي",
  "خليلك",
  "هرمون",
  "مضحكه",
  "وسترى",
  "الدفن",
  "كذبتي",
  "انقذك",
  "لوسيا",
  "مظهره",
  "دايفد",
  "مسائل",
  "للنقل",
  "نحيفة",
  "وابنه",
  "لطريق",
  "رخصتك",
  "وإنها",
  "وماهي",
  "إخافة",
  "جينات",
  "عظماء",
  "مارلي",
  "كوزمو",
  "ارأيت",
  "الخصر",
  "السام",
  "بومان",
  "فلديك",
  "خطوبة",
  "اقامة",
  "الوقح",
  "للكون",
  "ونعرف",
  "الأمد",
  "اسألي",
  "وبقية",
  "كومار",
  "نوادي",
  "جدولي",
  "دعتني",
  "حريتك",
  "واكثر",
  "حالفك",
  "لتونا",
  "حدثني",
  "نظرتم",
  "الوعى",
  "نصفها",
  "بقشيش",
  "لارسن",
  "فظيعا",
  "روابط",
  "حاجته",
  "فلتكن",
  "شوربة",
  "بأقرب",
  "راينا",
  "اجلسى",
  "العزل",
  "بغباء",
  "إبطال",
  "لقضية",
  "لويزا",
  "وفقدت",
  "إنزال",
  "رامبل",
  "نيلسن",
  "لحظـة",
  "احداث",
  "الخطف",
  "احدنا",
  "جمالا",
  "منتدى",
  "خمنوا",
  "انجاز",
  "واجبه",
  "كارما",
  "بامبي",
  "الجذع",
  "امسكه",
  "جلبها",
  "اتولى",
  "متجري",
  "متاهة",
  "نجدهم",
  "نوايا",
  "مشيئة",
  "الضيف",
  "لمتجر",
  "فنادق",
  "البهو",
  "مناشف",
  "رويال",
  "ذلــك",
  "وانتى",
  "والكل",
  "القيت",
  "هايفن",
  "وتبدو",
  "وكوني",
  "نتمشى",
  "وانهم",
  "عاتقك",
  "كيربي",
  "ولطيف",
  "لنقطة",
  "الحرص",
  "مساره",
  "لابني",
  "دبابة",
  "اسمعه",
  "انسوا",
  "مضيفة",
  "باندي",
  "اقصده",
  "خارجه",
  "مشارك",
  "دراسي",
  "صغيرى",
  "الطيف",
  "مدمني",
  "واقية",
  "اشتاق",
  "قنبله",
  "لينكس",
  "النخب",
  "نصطاد",
  "مزاجك",
  "مطارد",
  "علاجك",
  "اتسأل",
  "بركان",
  "الشقي",
  "كئيبة",
  "مصورة",
  "light",
  "هارلن",
  "برانش",
  "الامس",
  "لتظهر",
  "فاعلة",
  "بغضون",
  "لقبول",
  "عملته",
  "متخفي",
  "بامان",
  "رامزي",
  "بالعد",
  "إنخفض",
  "فرصته",
  "خدعنا",
  "حمامك",
  "هيرلي",
  "فيوري",
  "اصبعك",
  "بوقتي",
  "مقصود",
  "زمنية",
  "مؤذية",
  "وراثي",
  "بإنني",
  "راقبه",
  "لتحضر",
  "لتفكر",
  "قدمين",
  "ميريل",
  "بوابه",
  "نيسان",
  "نخرجك",
  "قومنا",
  "جانين",
  "طعاما",
  "بـذلك",
  "هيوغو",
  "وهـذا",
  "طفولة",
  "نعطيك",
  "خاتمي",
  "جنائي",
  "البند",
  "مقتول",
  "كتابا",
  "ومثير",
  "نظامك",
  "روحية",
  "فاتنا",
  "خصيصا",
  "واجلس",
  "وضعتك",
  "لأثبت",
  "خطابي",
  "هـنـا",
  "وابحث",
  "ضابطة",
  "مضبوط",
  "عالجت",
  "شبكات",
  "نطارد",
  "لأبيك",
  "مدونة",
  "شاهدك",
  "اتلقى",
  "عائشة",
  "بحاله",
  "بوسعى",
  "خطيبة",
  "قرارا",
  "احلام",
  "الطوق",
  "للفحص",
  "راسيل",
  "بنكهة",
  "مميزا",
  "عبقرى",
  "المده",
  "اخرجه",
  "باديء",
  "بدايه",
  "للقمة",
  "فراشه",
  "وتبقى",
  "مليسا",
  "إيقاع",
  "لشرطة",
  "الدار",
  "اواعد",
  "متفهم",
  "لفعلت",
  "قدمته",
  "ثلجية",
  "صدمته",
  "ملامح",
  "again",
  "جرايس",
  "إريكا",
  "اسباب",
  "بحساب",
  "لأعلم",
  "باورز",
  "مقصدك",
  "مخدرة",
  "اعلمك",
  "كأننى",
  "إحذري",
  "وكلنا",
  "فقدتي",
  "إتركه",
  "ليعيش",
  "ونظرت",
  "باركس",
  "واااو",
  "بادرة",
  "متطور",
  "مجبرة",
  "بيريز",
  "لسنين",
  "صدرية",
  "حلمنا",
  "فيمكن",
  "حلفاء",
  "للعنف",
  "لوحدة",
  "للجدل",
  "إنفاق",
  "نظرتي",
  "مسرحي",
  "العجز",
  "إضاءة",
  "بشرتك",
  "كثافة",
  "زعماء",
  "فينلي",
  "منتشر",
  "الدلو",
  "مضمار",
  "علقنا",
  "مطبخي",
  "متبقي",
  "اظهار",
  "عمومي",
  "نظارة",
  "منجذب",
  "ماكجي",
  "المقص",
  "ببطىء",
  "بالحظ",
  "وزراء",
  "بأربع",
  "طريفة",
  "لتقتل",
  "لزوجك",
  "طلابي",
  "محتاج",
  "عديده",
  "جايسن",
  "وقتهم",
  "باندا",
  "متعهد",
  "مأجور",
  "ومعها",
  "إسمحي",
  "خذيني",
  "إفادة",
  "احصلي",
  "خسروا",
  "جادين",
  "ماليا",
  "للقوة",
  "ادوات",
  "للنيل",
  "الطعن",
  "الطبع",
  "عباره",
  "للشعر",
  "فلنقم",
  "متيقن",
  "جائعه",
  "بموقف",
  "نوعان",
  "ولائك",
  "راجيف",
  "بدماء",
  "فايبر",
  "داربي",
  "إحتاج",
  "إبداع",
  "الزنا",
  "اميلي",
  "شــيء",
  "وأختي",
  "مصارع",
  "العزم",
  "وضعتي",
  "بكلام",
  "ايلاي",
  "اخرجى",
  "مابعد",
  "كينيا",
  "واتصل",
  "ثانوي",
  "واثقه",
  "رخصتي",
  "الآتي",
  "لعبور",
  "وكلاء",
  "صوتوا",
  "بالسر",
  "شارتي",
  "فرقتي",
  "بائعة",
  "ممفيس",
  "شطرنج",
  "كونهم",
  "للفتى",
  "فريقا",
  "كقائد",
  "مطمئن",
  "إبريل",
  "لأمري",
  "وهربت",
  "عرفتم",
  "خرطوم",
  "شوهدت",
  "مسروق",
  "للسوق",
  "الشلل",
  "ويأتي",
  "حزامك",
  "مقدما",
  "بارزة",
  "باولا",
  "خذوها",
  "هاسون",
  "مصيدة",
  "إعاقة",
  "هــذه",
  "استلم",
  "هدسون",
  "حزينه",
  "ودودة",
  "كسلاح",
  "إدخلي",
  "وسقطت",
  "نسائي",
  "thing",
  "لعبته",
  "رمضان",
  "امكنك",
  "مجرمة",
  "طاقته",
  "لجعلي",
  "وقعوا",
  "للحكم",
  "متلهف",
  "مفترس",
  "نعترف",
  "نراهم",
  "اعطاك",
  "ونكون",
  "وأعمل",
  "شاحنه",
  "إغراء",
  "للقمر",
  "هكـذا",
  "وخارج",
  "نتجول",
  "اختفي",
  "هدفهم",
  "لأقصى",
  "إللهي",
  "منافق",
  "مسجون",
  "هيونغ",
  "متقلب",
  "بريدج",
  "نظافة",
  "طفلتى",
  "إنجاح",
  "نتفقد",
  "كلامى",
  "وستون",
  "ازياء",
  "عديمي",
  "بحضور",
  "خصيتي",
  "وايلي",
  "خطبها",
  "ماليك",
  "لتلعب",
  "علاجي",
  "اليخت",
  "مهذبة",
  "لباقي",
  "النفع",
  "رأيهم",
  "انحني",
  "كانها",
  "لأخذه",
  "still",
  "معارض",
  "الاحظ",
  "مقفلة",
  "فواكه",
  "حققنا",
  "ضمنها",
  "قوانا",
  "عمرنا",
  "وكلها",
  "دوائك",
  "مشددة",
  "نفسهم",
  "جوثام",
  "بصدمة",
  "الطفح",
  "زيلدا",
  "الربط",
  "وتعال",
  "بأكبر",
  "خلالي",
  "لأخبر",
  "ميجيل",
  "ابتهج",
  "طاقمك",
  "حريصة",
  "مسرعة",
  "المشى",
  "إبلاغ",
  "نظرتك",
  "الدفء",
  "ارواح",
  "خـلال",
  "بطردي",
  "برينت",
  "اودرى",
  "إرجعي",
  "ولدوا",
  "صفقوا",
  "إيميل",
  "دواعى",
  "مروحة",
  "جرذان",
  "لنمضي",
  "بطنها",
  "زميله",
  "مكنسة",
  "بقاتل",
  "متجمد",
  "لآلاف",
  "الأهل",
  "رفاقه",
  "دورها",
  "امضيت",
  "لنفكر",
  "خداعك",
  "عشيقي",
  "ويلكس",
  "حمامة",
  "اخطاء",
  "فريدا",
  "الشقه",
  "عاريا",
  "الجين",
  "رابحة",
  "للسنة",
  "خطيره",
  "بلدها",
  "جبانة",
  "كثيرآ",
  "شبيهة",
  "شجاعا",
  "مفرطة",
  "جالوت",
  "لأنـه",
  "بإسمه",
  "العرس",
  "منفرد",
  "لأراك",
  "ثرثار",
  "نفسيا",
  "جسديا",
  "بقلبك",
  "نرتاح",
  "بالون",
  "نقوده",
  "نتوجه",
  "انعطف",
  "عالمه",
  "دوافع",
  "رباعي",
  "وافعل",
  "حريتي",
  "نيفيل",
  "قيامي",
  "عباءة",
  "إطراء",
  "بشرطة",
  "فلديه",
  "نعتبر",
  "الرطب",
  "وسهلا",
  "لاندي",
  "جدتها",
  "لعملي",
  "انبوب",
  "نظرات",
  "الجني",
  "رامبو",
  "ميلير",
  "خادمك",
  "الطبل",
  "خالتك",
  "خائنة",
  "متوجه",
  "مناجم",
  "الجوى",
  "متوقف",
  "لنسمع",
  "ليندن",
  "إليـه",
  "حضوره",
  "وذكية",
  "دوامة",
  "رئيسى",
  "اجتمع",
  "متطوع",
  "لدورة",
  "صبورة",
  "بضائع",
  "ففكرت",
  "مغلقه",
  "اجعلي",
  "هكتور",
  "فلنعد",
  "ببضعة",
  "وأنتى",
  "مخطئه",
  "ملقاة",
  "مغفلة",
  "ولاعة",
  "إهدار",
  "مدفوع",
  "بسرية",
  "قطعها",
  "للفصل",
  "اونيل",
  "عملات",
  "ماضية",
  "كايسى",
  "كابور",
  "اختبئ",
  "مرضاك",
  "لنجلس",
  "منظار",
  "للنار",
  "دعوها",
  "الكبر",
  "بعقلي",
  "كبرنا",
  "رفـاق",
  "طردنا",
  "الاخت",
  "ونسيت",
  "الخزي",
  "بدأتي",
  "منصبي",
  "بلادك",
  "صامتا",
  "اكلمك",
  "كثيفة",
  "صافرة",
  "مركزة",
  "مختلة",
  "محظور",
  "المسخ",
  "قابلي",
  "عصفور",
  "للبنك",
  "ضعيفا",
  "لتنقذ",
  "إسمعى",
  "مائية",
  "لابـد",
  "جريفن",
  "بنينا",
  "هوائي",
  "معامل",
  "مسطحة",
  "مفكرة",
  "البشع",
  "بعشاء",
  "الخان",
  "واقول",
  "بيلار",
  "كالذي",
  "إليزا",
  "اتصلى",
  "هوانغ",
  "دائمآ",
  "إتباع",
  "نائمه",
  "نلتزم",
  "العدة",
  "نهاجم",
  "لأخيك",
  "رئاسة",
  "كعكات",
  "البكر",
  "وألقي",
  "بساعة",
  "فأنها",
  "شهران",
  "وفيما",
  "انكسر",
  "محرجا",
  "ماربل",
  "حصلتى",
  "جناية",
  "للوضع",
  "إعلام",
  "مهنته",
  "هارلم",
  "اخلعي",
  "وغادر",
  "اليشا",
  "منهـا",
  "اتصور",
  "كواحد",
  "حافظي",
  "مستند",
  "نتخلى",
  "وسنرى",
  "مارلا",
  "قائدك",
  "مبللة",
  "وجهات",
  "نراجع",
  "وليلة",
  "نعيده",
  "بكلية",
  "خاطئه",
  "بعودة",
  "غراند",
  "ضحيته",
  "مرعوب",
  "نحبها",
  "بضربك",
  "بعمرك",
  "ادجار",
  "وعيها",
  "لمحطة",
  "ونأخذ",
  "الشيف",
  "رجعنا",
  "القرع",
  "رجليك",
  "مخيفا",
  "مؤقتا",
  "إتهام",
  "بقلبي",
  "رصاصه",
  "ويحصل",
  "مقدرة",
  "رأتني",
  "وايات",
  "مدرسي",
  "بدوري",
  "وبقيت",
  "وتطلب",
  "للجزء",
  "رقمية",
  "شجعان",
  "ويعيش",
  "بسيطا",
  "طيبين",
  "قاومت",
  "بيروت",
  "راجنر",
  "جدولة",
  "راستي",
  "للخطة",
  "قبضتي",
  "محترق",
  "قاعده",
  "للروح",
  "للهدف",
  "مختبئ",
  "وجدني",
  "متأثر",
  "عاشرت",
  "باكيت",
  "المحب",
  "صاغية",
  "كلمني",
  "ورائع",
  "إصعدي",
  "صفارة",
  "غينيا",
  "لأجلب",
  "وأقسم",
  "والأب",
  "عوامل",
  "منصبك",
  "بطلقة",
  "الصنف",
  "بدونى",
  "جائوا",
  "بذيئة",
  "عاشقة",
  "اصبعي",
  "رادار",
  "لنهرب",
  "مامدى",
  "مسبقة",
  "اللهو",
  "صاحبه",
  "كطبيب",
  "المهر",
  "شابين",
  "ماكرة",
  "شوستر",
  "فتحنا",
  "بسخاء",
  "فلسفة",
  "لاقول",
  "عبادة",
  "قابله",
  "اهناك",
  "الهلع",
  "خوزيه",
  "نظيفا",
  "موطنك",
  "ليروا",
  "الفرو",
  "كشرطي",
  "blood",
  "والحق",
  "اللية",
  "جوابك",
  "إقتحم",
  "بسكتة",
  "قديما",
  "وجعلت",
  "إيدين",
  "باركك",
  "عنيدا",
  "الفان",
  "لأناس",
  "قادما",
  "بتغير",
  "اغاني",
  "طائشة",
  "قاربك",
  "بمطعم",
  "شربنا",
  "دوجيت",
  "نختبئ",
  "نرتكب",
  "استغل",
  "مركزي",
  "مجهزة",
  "وأعني",
  "خنتني",
  "معيشة",
  "إراقة",
  "وأراد",
  "جربنا",
  "ملونة",
  "لجلسة",
  "لأقدم",
  "إنفجر",
  "للتـو",
  "للعين",
  "دارلا",
  "انجاب",
  "منبهر",
  "اسكتي",
  "مكونة",
  "نتركك",
  "بوعدي",
  "بدأته",
  "لنترك",
  "اطلبه",
  "نشرها",
  "خدمته",
  "فلورا",
  "شاشات",
  "الغزل",
  "جوفري",
  "شاحبة",
  "نتفهم",
  "عسلنا",
  "لأريك",
  "جاهدة",
  "مضيئة",
  "بضربي",
  "رحلنا",
  "امرنا",
  "بهجوم",
  "فينكس",
  "إليكي",
  "راكون",
  "للعدو",
  "عشائك",
  "اغنيه",
  "نقتحم",
  "بيدها",
  "للمره",
  "مجرفة",
  "بصفقة",
  "الخلط",
  "صالحك",
  "ويأخذ",
  "احذية",
  "الصمغ",
  "الحظر",
  "وصلتي",
  "شريان",
  "لفكرة",
  "ولائي",
  "فرقتك",
  "اسوكا",
  "غسالة",
  "هلوسة",
  "صانعة",
  "محتوى",
  "لخارج",
  "بقربك",
  "فتشوا",
  "وحدتي",
  "اقناع",
  "لهيئة",
  "انجيل",
  "بارده",
  "وأفكر",
  "مايهم",
  "الدسم",
  "زاندر",
  "ديلين",
  "مقززة",
  "المهد",
  "مبتهج",
  "بوضعه",
  "إضربه",
  "شرائه",
  "بإسمي",
  "للثقة",
  "فحصها",
  "دولان",
  "مايجن",
  "رحيله",
  "قبلهم",
  "لأخرى",
  "بعتها",
  "بغيضة",
  "رئيسا",
  "خانني",
  "كونان",
  "لأخرج",
  "معزول",
  "فتاته",
  "للضحك",
  "وبقدر",
  "وقاتل",
  "اصيبت",
  "ويزلي",
  "لتبقي",
  "شاكرة",
  "ندعهم",
  "نعناع",
  "نجعلك",
  "واتضح",
  "وكلهم",
  "ثروته",
  "بالشئ",
  "ماكين",
  "للمرض",
  "إنصرف",
  "القيء",
  "النسل",
  "الجهل",
  "للكلب",
  "ويسلر",
  "وسيما",
  "برقصة",
  "جراحي",
  "احسست",
  "شكاوى",
  "بأخذه",
  "خدمتي",
  "جارتي",
  "دافعت",
  "وسيدة",
  "محتار",
  "خطبكم",
  "جوابي",
  "وأخشى",
  "مصدره",
  "انجلس",
  "كارسن",
  "خبيثة",
  "خزينة",
  "وسرقة",
  "قريتي",
  "عقارب",
  "متسخة",
  "فارجو",
  "نالوا",
  "عمرهم",
  "دينفر",
  "مكياج",
  "ملاكم",
  "مجـرد",
  "القوا",
  "نقودا",
  "متفرغ",
  "غازات",
  "ولسوء",
  "نفاية",
  "طـوال",
  "الذقن",
  "مولعة",
  "لينقذ",
  "جيداي",
  "هيدرا",
  "حمامي",
  "فيجاي",
  "إلياس",
  "خوليو",
  "للسير",
  "مغتصب",
  "وتعيش",
  "ووقعت",
  "كريكت",
  "إلاهى",
  "ريمون",
  "براعة",
  "قبلتك",
  "إجبار",
  "بحقكم",
  "إبتسم",
  "غلطته",
  "قتلتم",
  "جامحة",
  "ذراعى",
  "رميها",
  "بأيدي",
  "الجدل",
  "اجريت",
  "مصاصو",
  "ورئيس",
  "لكامل",
  "وكانه",
  "دافيس",
  "كيندي",
  "اوقفه",
  "بالمخ",
  "متدين",
  "لهجتك",
  "بنادي",
  "وتشعر",
  "حماما",
  "بدلتك",
  "فارلي",
  "عدسات",
  "مهزلة",
  "مصابه",
  "خطأنا",
  "الهوت",
  "إحراج",
  "جمعها",
  "فاطمة",
  "للعام",
  "جريتا",
  "منفعة",
  "وأكره",
  "وابني",
  "نجحوا",
  "بوحدة",
  "مأدبة",
  "كعميل",
  "ماركت",
  "اضطرت",
  "عظامك",
  "بودرة",
  "وأبحث",
  "لتدخل",
  "ضربنا",
  "نتعقب",
  "للرأس",
  "الممل",
  "فرانز",
  "جراحه",
  "كامدن",
  "وأغلق",
  "كمدير",
  "شعروا",
  "بدلتي",
  "مشلول",
  "التزم",
  "الحبة",
  "لولاك",
  "الشبه",
  "راندى",
  "ميتان",
  "اتفقد",
  "ضعيفه",
  "وصيفة",
  "حقيبه",
  "لأختي",
  "إنشات",
  "ابعاد",
  "معتقل",
  "السرب",
  "بلاده",
  "طلبية",
  "للغرب",
  "دعوات",
  "وصديق",
  "غنائي",
  "فيستا",
  "لجولة",
  "السرى",
  "عاديه",
  "قبضتك",
  "برجاء",
  "حيوات",
  "ذهابه",
  "استحم",
  "ممزقة",
  "مناعة",
  "لننظر",
  "عشائي",
  "هانئة",
  "نشترك",
  "للعار",
  "لإبني",
  "دامون",
  "إبحثي",
  "معلمك",
  "وتبين",
  "حسبتك",
  "مفترق",
  "رميته",
  "قاسيه",
  "إيداع",
  "بأيام",
  "بنيته",
  "بلوتو",
  "ملفوف",
  "إنزلي",
  "محلها",
  "وأرسل",
  "وأفعل",
  "مالكة",
  "للشرح",
  "كشافة",
  "متردد",
  "مجزرة",
  "حاجات",
  "معهـا",
  "ارسلك",
  "خصائص",
  "إنهار",
  "كقطعة",
  "شولتز",
  "لألقي",
  "مدعوة",
  "غدائك",
  "خاتمة",
  "بلدتي",
  "غريفن",
  "رجالا",
  "هدفها",
  "عائدا",
  "دمائي",
  "لسيدة",
  "قادني",
  "ولكنة",
  "ديفيز",
  "ليبحث",
  "خاتمك",
  "لنحظى",
  "للقرف",
  "جماعه",
  "للركض",
  "اليمن",
  "زبوني",
  "اطفاء",
  "لنرجع",
  "طليقة",
  "لنكمل",
  "وطوال",
  "لأبقى",
  "نفقده",
  "house",
  "بالتر",
  "الآسف",
  "لتنام",
  "لتثبت",
  "امممم",
  "عينين",
  "الكلي",
  "منحها",
  "عجيبة",
  "فعليه",
  "هندسة",
  "بقوله",
  "لننسى",
  "عثرتم",
  "فورية",
  "ليبدأ",
  "وصولا",
  "بينكي",
  "باكلي",
  "نتيجه",
  "خواتم",
  "ديكور",
  "شريكى",
  "الربو",
  "لكتاب",
  "لمجلة",
  "واصبح",
  "والشر",
  "لمسرح",
  "الجبر",
  "شـكرا",
  "عددها",
  "ميرين",
  "جيمبو",
  "بأمري",
  "إشراف",
  "خجلان",
  "عملتي",
  "بآمان",
  "ناجين",
  "عظيـم",
  "وكيلي",
  "إدفعي",
  "إهمال",
  "ندافع",
  "قائدي",
  "الجرة",
  "كالدر",
  "روسيل",
  "شاهدا",
  "الدفة",
  "نتسلل",
  "بصيرة",
  "احزري",
  "قتلني",
  "نوافق",
  "خلالك",
  "فينست",
  "غافين",
  "دواسة",
  "ذكرها",
  "حملتي",
  "راعية",
  "إحراق",
  "الجهه",
  "مزايا",
  "العصي",
  "متكرر",
  "فعلـت",
  "عجائب",
  "بأسوأ",
  "بيردي",
  "ركزوا",
  "نادمة",
  "مصاصى",
  "حمايه",
  "اللبس",
  "اراهم",
  "حفيدك",
  "وبينه",
  "بلدية",
  "نستغل",
  "الروم",
  "إقتله",
  "طردته",
  "إيفون",
  "بيرتي",
  "ادخال",
  "نبقيه",
  "رئتيه",
  "بيكون",
  "بطردك",
  "صعوبه",
  "هاملت",
  "كابير",
  "قبائل",
  "angel",
  "ابطال",
  "بمجلس",
  "اعداء",
  "وتترك",
  "شعرتي",
  "اكتمل",
  "قادنا",
  "دفنها",
  "كيونج",
  "للبحر",
  "نظيفه",
  "فشلوا",
  "مجازي",
  "شيفرة",
  "ريفرز",
  "محشوة",
  "قبوله",
  "إبريق",
  "الكرم",
  "ميامى",
  "وعلمت",
  "معجزه",
  "بوتشي",
  "راغبة",
  "فارغه",
  "برانت",
  "هجرتك",
  "ونأمل",
  "اصمدي",
  "بمتجر",
  "الغدة",
  "عيناى",
  "غنيمة",
  "اتخلص",
  "جيداى",
  "ميؤوس",
  "ماكجى",
  "إسمعا",
  "لأختك",
  "بطاقه",
  "قطرها",
  "كشمير",
  "درامي",
  "ونبحث",
  "علشان",
  "للحزب",
  "رهانك",
  "وجهتك",
  "لصاحب",
  "ناقصة",
  "بقبول",
  "انزلق",
  "جارنا",
  "لتعقب",
  "الآلى",
  "وصفته",
  "وتموت",
  "منحنى",
  "مقاول",
  "عفريت",
  "لنوبة",
  "لتقلق",
  "حسناء",
  "ريجان",
  "اتحقق",
  "وتفقد",
  "خزائن",
  "مونيك",
  "شكوكي",
  "الهيب",
  "فعلـه",
  "ببعيد",
  "نقلهم",
  "منحته",
  "مخازن",
  "كشفنا",
  "للجسم",
  "لوقتك",
  "مضاءة",
  "عاريه",
  "الغرز",
  "ليبدو",
  "بطلنا",
  "لتبدو",
  "لانكم",
  "لتبحث",
  "إيليس",
  "طفولي",
  "حانات",
  "جعلتي",
  "رفضها",
  "لأنقذ",
  "بدلات",
  "قريبك",
  "حصانه",
  "ربـاه",
  "القمل",
  "عاديا",
  "موقفه",
  "مسالم",
  "الشاه",
  "مستمع",
  "ملزمة",
  "قياده",
  "الراس",
  "نحيلة",
  "منارة",
  "واطلب",
  "فاحصة",
  "فكرتى",
  "ملتصق",
  "معظمه",
  "الجلي",
  "مريبة",
  "بمزيد",
  "ابواب",
  "مصابا",
  "لأجلى",
  "وبناء",
  "هاجمك",
  "رابيت",
  "مسأله",
  "معرضة",
  "مرتدي",
  "الشغف",
  "نظريا",
  "حجزنا",
  "الوصل",
  "التفت",
  "عيونه",
  "الرضا",
  "كلينت",
  "اجدها",
  "لأدفع",
  "وأسرع",
  "كراسي",
  "كريمر",
  "عظامي",
  "بيترو",
  "اعددت",
  "ماكنة",
  "مائتي",
  "نتحكم",
  "فريجر",
  "لزوجة",
  "مالها",
  "الوتد",
  "وبيني",
  "لنصبح",
  "دايمن",
  "مكوين",
  "اكترث",
  "الامم",
  "بذاته",
  "منعها",
  "بهيئة",
  "فقدتك",
  "لاوري",
  "نحبهم",
  "مهددة",
  "وصفات",
  "غيرتي",
  "اقفزي",
  "الرضع",
  "الرأى",
  "دافعي",
  "بمجال",
  "اسقاط",
  "بسـبب",
  "حللنا",
  "لمهمة",
  "حولكم",
  "بوصلة",
  "الاهم",
  "إليسا",
  "لقاءك",
  "العطش",
  "اخلاء",
  "بيطري",
  "لسلاح",
  "قلبيه",
  "كلبها",
  "الشرط",
  "باسرع",
  "اهتمي",
  "للصور",
  "عزيزة",
  "صورنا",
  "حديثه",
  "مسليا",
  "ديدان",
  "اهداء",
  "مميزه",
  "ممسحة",
  "متميز",
  "إجهاض",
  "maybe",
  "لنعيد",
  "السىء",
  "حقوقك",
  "حذاءك",
  "نختلف",
  "إرتفع",
  "للشكر",
  "وكيلة",
  "طردني",
  "نصفهم",
  "اشاره",
  "شهيتي",
  "ركبته",
  "قائلة",
  "خلقنا",
  "مفلسة",
  "وحدتك",
  "عبدول",
  "عـلـى",
  "كبيـر",
  "جهازه",
  "ابقيا",
  "رواتب",
  "ونعلم",
  "ليدخل",
  "فحصنا",
  "كرهته",
  "فاصلة",
  "مطعمي",
  "والدم",
  "اتحرك",
  "مارجي",
  "بوليس",
  "نختبر",
  "دارما",
  "جيشنا",
  "بخارج",
  "للحمض",
  "شريفة",
  "اعلمه",
  "خاصية",
  "شواطئ",
  "وقتله",
  "مشبوه",
  "ميزان",
  "ودخلت",
  "البرت",
  "بومبي",
  "باتون",
  "فعلية",
  "خلفكم",
  "نحترم",
  "عتيقة",
  "كسرها",
  "الشنق",
  "جلبوا",
  "رضاكم",
  "اميرة",
  "طردها",
  "إدعاء",
  "فارغو",
  "رقابة",
  "لابنك",
  "ضمادة",
  "عطلتك",
  "وجودى",
  "قمتما",
  "وماتت",
  "عواطف",
  "اختلط",
  "معجبي",
  "الطمع",
  "قواها",
  "لامار",
  "ديكنز",
  "فاريل",
  "sweet",
  "قبلات",
  "لإننا",
  "ملوثة",
  "كيبنر",
  "بوردن",
  "ماقلت",
  "وضعوه",
  "لبيتي",
  "واردة",
  "ابتلع",
  "إعتبر",
  "فقــط",
  "منزلة",
  "نأكله",
  "كيلسي",
  "قتلتي",
  "لمئات",
  "بورما",
  "السوط",
  "مافيا",
  "جيتار",
  "مهبلي",
  "first",
  "ويبقى",
  "مشتعل",
  "ممرات",
  "ونظام",
  "جوستن",
  "إرتكب",
  "ليمان",
  "وجهاز",
  "الأزل",
  "مقامر",
  "وغرفة",
  "فادحة",
  "ليتصل",
  "معينه",
  "لمعظم",
  "لإحدى",
  "قبولي",
  "مشينا",
  "نفسـك",
  "ارسله",
  "لأنام",
  "فانها",
  "عجوزا",
  "وثاقي",
  "ندعها",
  "عواصف",
  "وأختك",
  "معطفه",
  "ايذاء",
  "مشتري",
  "مزودة",
  "الفكة",
  "قاطعة",
  "فخوره",
  "بدائي",
  "صورها",
  "دائري",
  "فرصنا",
  "مبتسم",
  "اردتم",
  "ويموت",
  "السله",
  "هزمتك",
  "ناثين",
  "الدهن",
  "حامية",
  "معالم",
  "نوعين",
  "ديفين",
  "ابعدي",
  "للنشر",
  "الضفة",
  "إباحي",
  "الهضم",
  "بريده",
  "إعتذر",
  "اظنني",
  "كروجر",
  "شامبو",
  "فريدى",
  "متعبا",
  "الاسد",
  "بوفيه",
  "قليلآ",
  "لفندق",
  "الصدى",
  "إسترح",
  "مغرية",
  "افتحى",
  "إنكما",
  "كوندي",
  "بصدده",
  "صحتها",
  "مدربي",
  "لأقلق",
  "ثقتهم",
  "فليكس",
  "black",
  "بطبيب",
  "هجرني",
  "مادية",
  "وتجعل",
  "صاحبك",
  "نجاحك",
  "حذائى",
  "قطعته",
  "إياهم",
  "كذبوا",
  "داخلى",
  "غازية",
  "ونشرب",
  "بتفقد",
  "إلفيس",
  "وقوية",
  "اخفقت",
  "القذف",
  "عمودي",
  "جينفر",
  "شفاهك",
  "squad",
  "كروسو",
  "كيسلر",
  "وجبتك",
  "بهاتف",
  "الميث",
  "إبليس",
  "وعملت",
  "وواضح",
  "لااحد",
  "حضرنا",
  "اتخلى",
  "جيـدة",
  "بالشك",
  "اتبول",
  "واسمه",
  "الخال",
  "مايبل",
  "طلاقي",
  "محصور",
  "كطفلة",
  "بهـذه",
  "ونفعل",
  "مشتتة",
  "ضواحي",
  "كيتون",
  "وليكن",
  "قبلتي",
  "اللوز",
  "اخرون",
  "عيدان",
  "الفهد",
  "فينوس",
  "ونساء",
  "اقاتل",
  "اعدها",
  "بشانه",
  "عبروا",
  "كيلير",
  "لأبدأ",
  "ليلند",
  "الورك",
  "leave",
  "خطايا",
  "لأطول",
  "الرنة",
  "فقالت",
  "ادعوك",
  "العمى",
  "اصابه",
  "جداول",
  "اكتبي",
  "مايسي",
  "متنقل",
  "حصوله",
  "عرقلة",
  "مذنبا",
  "غلطتى",
  "شارفت",
  "بقالة",
  "ماكسي",
  "قطعتي",
  "ملموس",
  "الخطا",
  "بالحر",
  "هاريت",
  "جيزيل",
  "دواين",
  "كتبوا",
  "رفعها",
  "اذكرك",
  "وأعيش",
  "فعلتك",
  "طاقمه",
  "اخرجت",
  "إحسان",
  "استقر",
  "قبلته",
  "وارسو",
  "التحق",
  "إلـهي",
  "لكــن",
  "ماقبل",
  "لباسك",
  "دعنـي",
  "رفيقى",
  "إرفعي",
  "إيزرا",
  "كلاود",
  "لتشعر",
  "حارسة",
  "جانبى",
  "جميعآ",
  "الشرج",
  "بروفة",
  "ماديا",
  "بجروح",
  "الـــ",
  "ليلعب",
  "الغدد",
  "عاقلة",
  "جلدها",
  "بصيغة",
  "لأنكي",
  "وقومي",
  "بحدود",
  "فأريد",
  "فرضية",
  "اراكي",
  "نشارك",
  "وخيمة",
  "ظننـت",
  "ناقلة",
  "عكسية",
  "امنية",
  "دولاب",
  "إذنكم",
  "زياده",
  "وابنك",
  "مسموع",
  "متحدث",
  "المرج",
  "اسماك",
  "للحاق",
  "للسحر",
  "انشاء",
  "these",
  "هولدر",
  "ممتنه",
  "بيرغر",
  "مدراء",
  "اليكي",
  "حقــا",
  "مبتلة",
  "منتجة",
  "بعملى",
  "وايتي",
  "مختفي",
  "بحريه",
  "جنديا",
  "بيندر",
  "لنعثر",
  "لقاتل",
  "شراءه",
  "ولحسن",
  "مرسوم",
  "وأترك",
  "شيوعي",
  "الفاي",
  "لنعيش",
  "لاجلي",
  "لمسته",
  "السرج",
  "عالمى",
  "دعنـا",
  "اراقب",
  "مجمدة",
  "حاولا",
  "خسرته",
  "لوضعه",
  "بصداع",
  "هارفى",
  "زجاجه",
  "بشرتي",
  "بإننا",
  "مسارح",
  "النسب",
  "بفرقة",
  "قبرها",
  "خدعني",
  "معركه",
  "زجاجي",
  "اجازه",
  "زوجتة",
  "مشوقة",
  "للغضب",
  "وقولي",
  "وباقي",
  "بزمام",
  "بمحطة",
  "بكمية",
  "نتدخل",
  "ولسوف",
  "والدو",
  "وإنني",
  "النحس",
  "معتمد",
  "بلوزة",
  "لبنان",
  "مبرمج",
  "وكثير",
  "للخير",
  "اخيرة",
  "روفوس",
  "ونرحل",
  "صحافة",
  "لقياس",
  "اضربك",
  "دعانا",
  "نبوءة",
  "بركوب",
  "قيامه",
  "صدقوا",
  "نتجنب",
  "حدوده",
  "عظامه",
  "لبلدي",
  "واجلب",
  "مفتون",
  "عازفة",
  "واعدة",
  "نتخيل",
  "داستن",
  "خالدة",
  "بنائه",
  "اطمئن",
  "اسئله",
  "اختها",
  "دمائك",
  "الدون",
  "دوائي",
  "دخلتي",
  "غضبها",
  "لكلية",
  "اهدئى",
  "بعيدآ",
  "حانتي",
  "لورنا",
  "وعندي",
  "نصيحه",
  "اوصلك",
  "الانف",
  "فوسكو",
  "النتن",
  "إلحاق",
  "كليتي",
  "المني",
  "ننسحب",
  "بصوره",
  "صدقته",
  "الخنق",
  "عرفتى",
  "ميللر",
  "خطوتك",
  "كورين",
  "عطلتي",
  "مربحة",
  "ببقية",
  "قصائد",
  "دورتي",
  "رؤيـة",
  "إمضاء",
  "هـيـا",
  "التسع",
  "ديسكو",
  "اوعدك",
  "واترك",
  "خادمه",
  "وقائي",
  "انتصر",
  "بشعرك",
  "إفتحه",
  "فندقي",
  "قافلة",
  "الهمج",
  "كيــف",
  "بيترا",
  "إضراب",
  "بقياس",
  "صحتكم",
  "متقنة",
  "اعطاه",
  "منفتح",
  "وشراء",
  "اسقطت",
  "معلوم",
  "إشتري",
  "فيبدو",
  "ثيلما",
  "لكلام",
  "هيوجو",
  "فيلبس",
  "نلتقى",
  "وأبدأ",
  "نسأله",
  "بشأنى",
  "كتفيك",
  "عصيان",
  "اعراض",
  "فردية",
  "عانوا",
  "وجعله",
  "طوماس",
  "افعلى",
  "باتيل",
  "جالون",
  "راقبت",
  "وبدلا",
  "جدولك",
  "حديدي",
  "حركتك",
  "ثابته",
  "لعقود",
  "مفتول",
  "روزلي",
  "اقسمت",
  "الكثر",
  "بطلاء",
  "قبضته",
  "لاسفل",
  "غادرا",
  "الأسى",
  "اظهرت",
  "كلوني",
  "اتيتي",
  "عميلا",
  "احبهم",
  "قاربه",
  "بتمعن",
  "وأسوأ",
  "النضج",
  "لوبوس",
  "ووسيم",
  "ذريعة",
  "الزبد",
  "music",
  "ابدوا",
  "بسببى",
  "صانعي",
  "موران",
  "قررتي",
  "حلمات",
  "الجذر",
  "بعيون",
  "الركل",
  "كابول",
  "خاوية",
  "كبديل",
  "لأخذي",
  "موتنا",
  "مولدي",
  "متعصب",
  "نطالب",
  "الروث",
  "العطف",
  "النقش",
  "فيهما",
  "ريفية",
  "مستجد",
  "جيدان",
  "درايف",
  "money",
  "إقفال",
  "قبولك",
  "افهمه",
  "زبونة",
  "دوريت",
  "جليدي",
  "زوجية",
  "لأكبر",
  "لدرجه",
  "هاديس",
  "فيضان",
  "نتوصل",
  "ببغاء",
  "جيــد",
  "بلدان",
  "للوجه",
  "غيثرو",
  "اريني",
  "اختلف",
  "عبرها",
  "لمقتل",
  "الدمج",
  "صغارا",
  "الاصل",
  "اقضوا",
  "شجاعه",
  "دفعات",
  "بتاتا",
  "الرمي",
  "مرضها",
  "حقوقه",
  "محروق",
  "اسئلك",
  "حالهم",
  "لتدفع",
  "عجلوا",
  "بناءا",
  "بكتاب",
  "روبيه",
  "الشدة",
  "اعاده",
  "كارثه",
  "جميـل",
  "بمضرب",
  "ظهورك",
  "متحضر",
  "وعودي",
  "حاذري",
  "إيليا",
  "مروري",
  "عددنا",
  "بلانش",
  "امامه",
  "قاذفة",
  "كأحمق",
  "ابدئي",
  "اسحبي",
  "مسالة",
  "ويقتل",
  "مخارج",
  "حارسي",
  "بوفاة",
  "دليلك",
  "وتحضر",
  "ذكائك",
  "نقلنا",
  "فتشنا",
  "للدور",
  "عربتك",
  "بنزهة",
  "مرادك",
  "لفائف",
  "وفيات",
  "مجاري",
  "مورلي",
  "وضيعة",
  "الأرق",
  "ثاقبة",
  "ليفون",
  "خطابا",
  "مارلى",
  "قسمنا",
  "لاجله",
  "ايصال",
  "طارئه",
  "دامين",
  "مولوك",
  "قسيمة",
  "النهج",
  "هايتس",
  "صالحا",
  "احتفل",
  "وإليك",
  "للحجز",
  "بقدرك",
  "لصورة",
  "مررها",
  "جنسنا",
  "عساها",
  "واشعر",
  "كوبير",
  "لينون",
  "بالية",
  "مخترع",
  "وقفنا",
  "وسامة",
  "إسألي",
  "بقدوم",
  "اعملي",
  "ديكون",
  "خطأها",
  "dream",
  "جوليو",
  "لنصعد",
  "عسانا",
  "قبعته",
  "طرزان",
  "إرحلي",
  "للأمل",
  "بوجهه",
  "جنونك",
  "كلوزو",
  "التقت",
  "منطاد",
  "لنسرع",
  "ميستك",
  "لاعلى",
  "لقليل",
  "الوصي",
  "معادن",
  "إختطف",
  "هاركر",
  "وتظهر",
  "السبل",
  "اتعجب",
  "مصدرك",
  "ادخلى",
  "كزوجة",
  "دعامة",
  "خطتهم",
  "غرورك",
  "متحول",
  "بخدعة",
  "هيونج",
  "داكنة",
  "عملتم",
  "إسأله",
  "كعكتي",
  "كاذبه",
  "الأجل",
  "كيفيه",
  "akram",
  "فتحوا",
  "وقليل",
  "خرجتي",
  "قاموس",
  "هروبه",
  "مبهجة",
  "شعورا",
  "لاصقة",
  "جبريل",
  "غثيان",
  "شاطيء",
  "الذان",
  "اغربي",
  "متعلم",
  "وستجد",
  "اصدار",
  "اللهى",
  "الرفع",
  "ولسبب",
  "بعلاج",
  "كيندا",
  "وعادة",
  "مراهن",
  "جوبال",
  "بيترز",
  "دعوهم",
  "ثقتها",
  "لنصنع",
  "ضربوا",
  "لتحمي",
  "ملطخة",
  "وسبعة",
  "دميتي",
  "رجلها",
  "فذهبت",
  "بوركت",
  "بجرعة",
  "اثبتي",
  "بدأتم",
  "الايس",
  "woman",
  "ومكتب",
  "بهكذا",
  "كرجال",
  "هزمنا",
  "دفاعا",
  "إصبعه",
  "الغنى",
  "نختفي",
  "صقلية",
  "اوستن",
  "بروتس",
  "ليلـة",
  "إرنست",
  "ربطات",
  "sorry",
  "ومعظم",
  "فعلتة",
  "قدومه",
  "هاجمه",
  "ليثبت",
  "وملكة",
  "فوجئت",
  "للقصر",
  "مندفع",
  "طرقنا",
  "مزيفه",
  "مظاهر",
  "مسكني",
  "الحدس",
  "شاعري",
  "فاروق",
  "ميئوس",
  "بسرور",
  "مشوهة",
  "فريقى",
  "نشرات",
  "نلاحظ",
  "الحكة",
  "اعثري",
  "معاقب",
  "رقصنا",
  "كضابط",
  "درسنا",
  "ولأول",
  "اللغه",
  "كيران",
  "للذعر",
  "كسولة",
  "لأقتل",
  "خالفت",
  "شديده",
  "ونحضر",
  "شريكا",
  "حتفها",
  "لأتصل",
  "ثيابه",
  "فالون",
  "نظامه",
  "مباحث",
  "إفترض",
  "لوحدى",
  "القطر",
  "اللفة",
  "بلانك",
  "للكتب",
  "جواهر",
  "حاملي",
  "وخطير",
  "وسيطة",
  "مزاجه",
  "الهوى",
  "عليكن",
  "وابدأ",
  "محقون",
  "فيولا",
  "مهملة",
  "لذيذه",
  "برادا",
  "إدوين",
  "اعتدى",
  "لفافة",
  "الثلث",
  "إنجيل",
  "ملاهي",
  "وتنظر",
  "happy",
  "ويجعل",
  "خليفة",
  "كلمتى",
  "راموس",
  "بستان",
  "ببيعه",
  "إنهـم",
  "روكيت",
  "محملة",
  "نشيطة",
  "other",
  "افساد",
  "فازوا",
  "ملفها",
  "لينين",
  "مولان",
  "وأكبر",
  "جولين",
  "إلهنا",
  "طومسن",
  "عالما",
  "اعصار",
  "واليس",
  "كاتلر",
  "جنودك",
  "رجـال",
  "والرب",
  "ثابتا",
  "برئيس",
  "بقيتي",
  "لتخطي",
  "بيدنا",
  "زيتون",
  "ثملين",
  "إيديث",
  "هاهنا",
  "لأعيد",
  "منبوذ",
  "لازلو",
  "شاطىء",
  "قلناه",
  "مشرحة",
  "عقابك",
  "لأسمع",
  "مجدية",
  "داركن",
  "اذهبو",
  "القطه",
  "جائني",
  "جربها",
  "بقرار",
  "نسمعك",
  "مثلية",
  "لذكرى",
  "غوميز",
  "ونعمل",
  "هجرته",
  "الصفة",
  "وزوجك",
  "لتطلب",
  "موزلي",
  "وأرغب",
  "لتراه",
  "ماريل",
  "ولدان",
  "بأناس",
  "مشدود",
  "اضعاف",
  "ويطلق",
  "النقر",
  "متفوق",
  "ليدفع",
  "بطرده",
  "لنتصل",
  "داوني",
  "بعدين",
  "ارتدت",
  "مؤخره",
  "جبانا",
  "النزف",
  "ملهمة",
  "وعشرة",
  "الطبى",
  "اتاكد",
  "حطموا",
  "كولبي",
  "عميله",
  "اعماق",
  "كحارس",
  "بوعدك",
  "لكنـي",
  "فطورك",
  "صدقتك",
  "قابيل",
  "طباخة",
  "اكرهه",
  "واجبى",
  "ماسبب",
  "إنتشر",
  "داليك",
  "عجوزة",
  "متسكع",
  "الصدد",
  "صنبور",
  "كأنكم",
  "مطفأة",
  "لألعب",
  "كلاما",
  "لحالي",
  "كوتون",
  "اخرسى",
  "دمائه",
  "النبأ",
  "كونلي",
  "girls",
  "الكشك",
  "ويطلب",
  "ماونت",
  "لأموت",
  "ونلعب",
  "الطهو",
  "انتمي",
  "قلقنا",
  "نفسـي",
  "متحكم",
  "نهارك",
  "لوميس",
  "اواجه",
  "للجري",
  "رودنى",
  "علمها",
  "طهران",
  "إليـك",
  "مايبي",
  "ويسلى",
  "مزعجا",
  "مكابح",
  "طلابك",
  "للشقة",
  "احافظ",
  "اسرعى",
  "بتعقب",
  "بشبكة",
  "ممكنه",
  "للبدأ",
  "نفذوا",
  "النقص",
  "الثمل",
  "فأنتي",
  "ليظهر",
  "قرأنا",
  "فريتز",
  "وانما",
  "بدورك",
  "ثروتي",
  "ناتجة",
  "جزئيا",
  "نسيتم",
  "لحانة",
  "وترحل",
  "امامى",
  "ونأكل",
  "رجــل",
  "رأيـت",
  "باوني",
  "الهبة",
  "إجهاد",
  "هايزل",
  "ومعهم",
  "ابنهم",
  "معيار",
  "ثيران",
  "بدائل",
  "ادخلا",
  "ديونك",
  "نطلبه",
  "معجبا",
  "قواته",
  "مطاطي",
  "برادو",
  "عدائي",
  "نسبيا",
  "وأرجو",
  "كبروا",
  "صائدي",
  "ايستر",
  "الجرف",
  "بغسيل",
  "لأشعر",
  "متطرف",
  "ونترك",
  "لتتبع",
  "بارود",
  "منفعل",
  "ناجون",
  "شفاهي",
  "اعجبت",
  "نومنا",
  "للشرق",
  "قواهم",
  "نكران",
  "عرفها",
  "صفيحة",
  "ضعوها",
  "صادقه",
  "إبدأي",
  "كرسيك",
  "معنـا",
  "عشيقك",
  "بعيار",
  "مقلوب",
  "الاسف",
  "منتفخ",
  "الثقل",
  "الصدأ",
  "ردائي",
  "عقاري",
  "نفتقد",
  "لهاتف",
  "بزوجي",
  "والأم",
  "كاحلك",
  "نسختي",
  "رومني",
  "مؤلما",
  "فظيعه",
  "dance",
  "جينسن",
  "لكمات",
  "إحصاء",
  "خطرون",
  "إنفصل",
  "جيونغ",
  "لدليل",
  "شروطي",
  "واريك",
  "لسبعة",
  "مهمتى",
  "ناريه",
  "لقولك",
  "اغسطس",
  "روشيل",
  "الوسخ",
  "هبطنا",
  "جميـع",
  "ويندى",
  "لاركن",
  "قرأتي",
  "هاوية",
  "بطولي",
  "ماشية",
  "وصيته",
  "دونـا",
  "مفعمة",
  "لكوكب",
  "كشاهد",
  "رحمتك",
  "ديانة",
  "كورال",
  "بيومك",
  "إنعاش",
  "امرها",
  "واجعل",
  "إحترق",
  "نجاحا",
  "لرؤيه",
  "لنركز",
  "لأغير",
  "مشاغب",
  "بيرجر",
  "الصرع",
  "مبهمة",
  "ارفعه",
  "الحرج",
  "معقده",
  "وراقب",
  "إصمتي",
  "ويعلم",
  "لوجبة",
  "قررتم",
  "مثالا",
  "للجهة",
  "مشارف",
  "مقيمة",
  "ارجاع",
  "اتأخر",
  "الضلع",
  "اصمتى",
  "مفاعل",
  "رأيـك",
  "شائبة",
  "ايمان",
  "إعتنى",
  "محفوظ",
  "فيلدز",
  "شفتاي",
  "لأمور",
  "لكبار",
  "ماخطب",
  "خداعه",
  "لتولي",
  "كناية",
  "وتبحث",
  "ارجاء",
  "كراون",
  "مقاسك",
  "لمادا",
  "بسباق",
  "ملكهم",
  "وسرقت",
  "لقائد",
  "مقعده",
  "بموته",
  "لسباق",
  "امراه",
  "حسابى",
  "محمصة",
  "غريتا",
  "ميريك",
  "كينان",
  "وتأكل",
  "شراؤه",
  "ماثيس",
  "بريئا",
  "مفاصل",
  "دينار",
  "حاضنة",
  "اموات",
  "ليبيا",
  "بدليل",
  "للامر",
  "حيالك",
  "ننتصر",
  "الأيس",
  "متهمة",
  "عربتي",
  "بوجبة",
  "وابقي",
  "شايان",
  "بجمال",
  "اختاه",
  "خـارج",
  "اسافر",
  "لنقضي",
  "لتأكل",
  "رشيقة",
  "دستور",
  "عمولة",
  "لوحتي",
  "احرار",
  "مكتوف",
  "شانتي",
  "لنبقي",
  "رأتها",
  "ليفين",
  "المشع",
  "وغريب",
  "ثالثا",
  "وأبقى",
  "الصخب",
  "نجاحي",
  "مثارة",
  "طالبا",
  "فينيس",
  "اتدرب",
  "خادعة",
  "كاهيل",
  "هزلية",
  "جثتين",
  "املكه",
  "الألة",
  "للطلب",
  "شنايا",
  "وتسبب",
  "انتزع",
  "فضيعة",
  "اغراض",
  "راكبي",
  "طاهية",
  "براند",
  "اعجاب",
  "ازعجك",
  "ارضية",
  "شمشون",
  "وأقوى",
  "بتتبع",
  "العمد",
  "صدمات",
  "اساسا",
  "بناتك",
  "محررة",
  "مصغرة",
  "ناتلي",
  "شغلها",
  "الألب",
  "وأنـت",
  "ومنزل",
  "للكسر",
  "جـورج",
  "عالقا",
  "مداخل",
  "نتبعه",
  "منتبه",
  "ناموا",
  "حماسي",
  "كبريت",
  "باأنه",
  "مريحا",
  "doing",
  "فاتتك",
  "معلمه",
  "كشريك",
  "قناعك",
  "إشعاع",
  "بقدمي",
  "لمستك",
  "معاطف",
  "إزداد",
  "جاءني",
  "شيستر",
  "العمه",
  "دموعك",
  "اساسي",
  "اسحبه",
  "اشترك",
  "بوقته",
  "بودين",
  "لقتال",
  "ليونا",
  "ليسلي",
  "لقرار",
  "طموحة",
  "طلبتم",
  "ناندو",
  "start",
  "متعقب",
  "لوران",
  "دوفال",
  "القشة",
  "خوذتك",
  "هيربي",
  "مساكن",
  "التبت",
  "لوكان",
  "دافيء",
  "حنجرة",
  "اسمهم",
  "فيجاى",
  "place",
  "لحقوق",
  "دخلتم",
  "كروغر",
  "الشفق",
  "لروما",
  "مرتعب",
  "حيـاة",
  "وقلبي",
  "ربطها",
  "كيتنغ",
  "صورهم",
  "إشربي",
  "شعورى",
  "ويلسن",
  "وتحقق",
  "لأنـي",
  "لحاله",
  "وبنفس",
  "رانكل",
  "فالآن",
  "ميستي",
  "فقررت",
  "بعدوى",
  "موهان",
  "مثبتة",
  "عودتى",
  "اتبعت",
  "بكافة",
  "غارزا",
  "وارحل",
  "هنيئا",
  "اتدخل",
  "وأتيت",
  "ليعلم",
  "دموعي",
  "وأسفل",
  "للوحة",
  "منسحب",
  "ذكروا",
  "جنودي",
  "حفيدة",
  "ونطلب",
  "وتحمل",
  "وقفوا",
  "وشأنك",
  "بشجرة",
  "لأبني",
  "وابقى",
  "نفسنا",
  "ووصلت",
  "وأجلب",
  "إشترت",
  "فابيو",
  "وأجلس",
  "فيكون",
  "وتعني",
  "بيتكم",
  "ابقيه",
  "ميثاق",
  "ووضعه",
  "مقبلة",
  "متقطع",
  "الثري",
  "القنب",
  "إيـزي",
  "اليله",
  "متوفي",
  "مخططة",
  "devil",
  "لتعيد",
  "للقصة",
  "عطلات",
  "مقلاة",
  "كفانا",
  "ويفعل",
  "ظننتي",
  "حماسة",
  "لتصنع",
  "صبورا",
  "ويترك",
  "شكاوي",
  "اوقعت",
  "لوكنت",
  "ارسلي",
  "الفوج",
  "مشرفة",
  "ضيوفك",
  "النعش",
  "كرولي",
  "ودائع",
  "اليكى",
  "بنيتي",
  "إرهاب",
  "الجان",
  "للوزن",
  "حريته",
  "الجعه",
  "الفوق",
  "جينيس",
  "عقبات",
  "باحثة",
  "حركته",
  "صباحي",
  "وسيله",
  "بوعده",
  "بعدئذ",
  "فأنهم",
  "باستا",
  "اسحاق",
  "ولكنا",
  "عصرية",
  "لحملة",
  "انقضى",
  "فكانت",
  "عرضية",
  "وتلعب",
  "كتمان",
  "وعادت",
  "كأفضل",
  "نسمعه",
  "مصدري",
  "ملكات",
  "زفافه",
  "الحصى",
  "للهند",
  "ضربهم",
  "مهامك",
  "نـحـن",
  "الرتل",
  "جاهلة",
  "وبفضل",
  "ديارك",
  "صبـاح",
  "غالون",
  "صغاري",
  "شمسنا",
  "ذكائي",
  "الوقع",
  "إركبي",
  "لأنهي",
  "فضـلك",
  "جربني",
  "صالحه",
  "دونما",
  "saleh",
  "لتفهم",
  "قافية",
  "الآلم",
  "إخوتك",
  "الصفح",
  "كرتون",
  "موهبه",
  "دراجه",
  "بوسكو",
  "جاكيت",
  "وأجعل",
  "وشراب",
  "للخطأ",
  "العذب",
  "مدللة",
  "الجرى",
  "بقاعة",
  "بروان",
  "محرمة",
  "دمرنا",
  "خطرين",
  "إيقاظ",
  "جادون",
  "واعمل",
  "لأنـك",
  "لأقضي",
  "متجرك",
  "قريتك",
  "اتعني",
  "بنظري",
  "ماكوي",
  "بحانة",
  "ديفرو",
  "لإنهم",
  "احبكم",
  "مغاير",
  "راحتي",
  "مسارك",
  "إشاره",
  "شافيز",
  "رفيقه",
  "كابوا",
  "بجودة",
  "وافضل",
  "ليحمي",
  "بجسدك",
  "مسافه",
  "بورطة",
  "كتبنا",
  "نلاحق",
  "شارعي",
  "wrong",
  "الحاج",
  "نصيبه",
  "today",
  "نريهم",
  "رئتيك",
  "لقاعة",
  "ليهدأ",
  "احمله",
  "those",
  "للحدث",
  "عصابه",
  "عنهـا",
  "مدتها",
  "بطانة",
  "فاعله",
  "اوبرا",
  "مارسل",
  "اراكى",
  "بمعني",
  "مخطأة",
  "صاعقة",
  "ريسلر",
  "إذلال",
  "كأسان",
  "بجلسة",
  "وصاية",
  "هاتشي",
  "break",
  "حزامي",
  "إحباط",
  "لاأظن",
  "كحولي",
  "وتفكر",
  "بقبلة",
  "شـيئا",
  "لدعوة",
  "فوتنا",
  "اشكال",
  "ابتاه",
  "مدخنة",
  "اتيتم",
  "داوسن",
  "اصنعي",
  "بشريط",
  "عبوات",
  "بزواج",
  "شؤوني",
  "محزنة",
  "جيرمى",
  "نعمله",
  "الدهر",
  "الالة",
  "موقنة",
  "لياقة",
  "وتقدم",
  "الجذب",
  "ثروات",
  "اعياد",
  "وطنهم",
  "اخوتي",
  "اهداف",
  "حجرتي",
  "ممتنا",
  "ديليا",
  "نحضره",
  "بـدون",
  "للقطط",
  "ولنرى",
  "حددوا",
  "حلمها",
  "وينجر",
  "كلفنا",
  "وهاهو",
  "اردتك",
  "لاتيه",
  "للإله",
  "وهاهي",
  "عروسة",
  "كلبنا",
  "مناظر",
  "شيرون",
  "ابعده",
  "جيرسى",
  "فرقعة",
  "نقودى",
  "لإبنك",
  "اجلسا",
  "نورين",
  "الغسق",
  "وضوحا",
  "لسؤال",
  "احضرى",
  "وأنقذ",
  "صلابة",
  "ومعنا",
  "فانني",
  "جنسيه",
  "للعبث",
  "عسكرى",
  "عادتك",
  "بلقاء",
  "للحمل",
  "الهمس",
  "ناديت",
  "مدهشه",
  "معافى",
  "حــدث",
  "إبهام",
  "لأشرح",
  "فانهم",
  "روائح",
  "اكمال",
  "عظمية",
  "ورحلت",
  "بالحي",
  "كغطاء",
  "مسرعا",
  "لتقضي",
  "اوقفت",
  "مارشا",
  "الويب",
  "جاشوا",
  "لأهتم",
  "محصول",
  "طردتك",
  "جلوسي",
  "مذياع",
  "عامود",
  "ميراث",
  "متمسك",
  "رمزية",
  "ميرتل",
  "كابوت",
  "مايكى",
  "واقوم",
  "فوكين",
  "الضبط",
  "مأخوذ",
  "لـذلك",
  "بأخيك",
  "مولدك",
  "إيريس",
  "بنسات",
  "رائدة",
  "متطفل",
  "اختلق",
  "المسك",
  "شاذين",
  "بلاكي",
  "رادها",
  "جينكس",
  "لشبكة",
  "الالي",
  "ممممم",
  "مهاجر",
  "ونجعل",
  "نقطتي",
  "دونلي",
  "وكانك",
  "كيشان",
  "خادمي",
  "حراسه",
  "منافع",
  "متعفن",
  "اطباء",
  "بعينك",
  "رجليه",
  "اتجهت",
  "هاللو",
  "نتمرن",
  "صبيان",
  "معصمي",
  "لنجلب",
  "مجرما",
  "لأدخل",
  "جذابه",
  "عادتي",
  "شتاين",
  "لتقبل",
  "ومثلك",
  "جوسون",
  "فصلها",
  "ضيوفي",
  "رسميه",
  "بيرون",
  "نتتبع",
  "شبابك",
  "فولكر",
  "لتجلب",
  "خطابه",
  "لتحدث",
  "كسبنا",
  "نتمتع",
  "جافيد",
  "للذات",
  "البعث",
  "إفراغ",
  "بأنـه",
  "اللغم",
  "اطلقي",
  "البجع",
  "دفاعك",
  "بارنى",
  "ودفعت",
  "مدهشا",
  "جاندي",
  "نزلنا",
  "might",
  "موطنه",
  "خاليه",
  "بخـير",
  "عصبيا",
  "إقترح",
  "احرصي",
  "المنح",
  "مضغوط",
  "كيلوا",
  "صاحية",
  "العود",
  "اغلقه",
  "صيفية",
  "صاحبى",
  "مذهول",
  "alone",
  "كيرلي",
  "قدماك",
  "غامرة",
  "الجدي",
  "قاطعت",
  "إنتحر",
  "استقل",
  "نظرها",
  "اتفقت",
  "ويظهر",
  "ابتعت",
  "فتحته",
  "عصرنا",
  "اندري",
  "طاهرة",
  "كيرين",
  "نشأنا",
  "هايتي",
  "عثمان",
  "إلتقى",
  "رفضنا",
  "three",
  "ويجري",
  "همسات",
  "ريفين",
  "ماهون",
  "فتانا",
  "لذيذا",
  "اخلوا",
  "رملية",
  "كارثي",
  "جاهدا",
  "إزاحة",
  "بأسمك",
  "بالفن",
  "وتخبر",
  "لطفلي",
  "نسختك",
  "جندية",
  "مالكي",
  "ولمدة",
  "العصى",
  "قدرما",
  "ماوتش",
  "خفيفه",
  "هاواى",
  "مرضاه",
  "الوتر",
  "عاطلة",
  "لاااا",
  "كأنما",
  "بينغو",
  "إنقلب",
  "وأمسك",
  "لتحظى",
  "نضعهم",
  "نقالة",
  "زاكري",
  "اهدأى",
  "دوسون",
  "وساعد",
  "وجبتي",
  "tamed",
  "بأختي",
  "بورصة",
  "محرقة",
  "نقلوا",
  "ريفير",
  "راشدة",
  "فريدو",
  "وسعيد",
  "انصحك",
  "لقولي",
  "جريسي",
  "الصحى",
  "بداعي",
  "إيزاك",
  "شورتي",
  "إجلسى",
  "مكثفة",
  "للبيض",
  "لجدتي",
  "واضاف",
  "بالاس",
  "واترز",
  "لتهرب",
  "لويجي",
  "زبادي",
  "دخلاء",
  "لزواج",
  "لاسـي",
  "وتجلس",
  "صوبوا",
  "ننقله",
  "وقطعة",
  "ريبكا",
  "جثمان",
  "وتلقي",
  "بافضل",
  "اتضور",
  "شحنها",
  "نانسى",
  "مفضلة",
  "لحيتك",
  "لشعور",
  "مؤسفة",
  "ديفلن",
  "ظهرنا",
  "مدعاة",
  "زويلا",
  "بنسخة",
  "لأقرب",
  "بزوجة",
  "كبقية",
  "قاعات",
  "علمنى",
  "حققته",
  "العنة",
  "داعمة",
  "اشباح",
  "قضيـة",
  "موكلى",
  "اردتى",
  "مرشحة",
  "فريبو",
  "البته",
  "احدثك",
  "منومة",
  "صوتنا",
  "كروفت",
  "اخرجك",
  "بغطاء",
  "متمرد",
  "فوجدت",
  "متغير",
  "بابها",
  "ميونخ",
  "بابنا",
  "غيتار",
  "للسبب",
  "اقرأي",
  "بيتهم",
  "لضربة",
  "الهدر",
  "البغل",
  "الجرد",
  "معصمك",
  "ارجوا",
  "لاكثر",
  "للنهر",
  "اشعال",
  "مشحون",
  "حياتة",
  "هيللر",
  "حلاوة",
  "نخبنا",
  "خاننا",
  "باكشي",
  "كيلوج",
  "التين",
  "بكلمه",
  "منعهم",
  "كريهه",
  "منعنا",
  "هوندا",
  "دلفين",
  "غيرته",
  "محايد",
  "وبشدة",
  "جامعه",
  "لدعمك",
  "كشفها",
  "فقدتم",
  "مربعة",
  "هيرست",
  "الغيت",
  "كونتي",
  "خائبة",
  "ممرضه",
  "خططوا",
  "ناحيه",
  "افتتح",
  "thank",
  "شعرهم",
  "نهارا",
  "رايفن",
  "اشارك",
  "بالغش",
  "لزفاف",
  "جثثهم",
  "لبيته",
  "مخلوط",
  "ضغائن",
  "بفتحه",
  "شيئـا",
  "حارقة",
  "منحهم",
  "منظرك",
  "اسلحه",
  "عربون",
  "النحت",
  "لذهاب",
  "بنشاط",
  "للكرة",
  "قدماء",
  "حراري",
  "جرحتك",
  "وشركة",
  "اثينا",
  "بغناء",
  "صعودا",
  "حديقه",
  "قميصا",
  "ظهوره",
  "للجثة",
  "جافين",
  "طاغية",
  "عاقبة",
  "الهذا",
  "حبيبه",
  "بعضهن",
  "اللثة",
  "غرفـة",
  "عشتها",
  "مخترق",
  "الكتل",
  "وإذهب",
  "عرضهم",
  "صبيحة",
  "ماسوف",
  "بحماس",
  "شاسعة",
  "وسائد",
  "عوائق",
  "ناجحا",
  "جناحي",
  "واسمع",
  "مسكتك",
  "فاتحة",
  "فرامل",
  "مطعمك",
  "لمدير",
  "comes",
  "فراغي",
  "مساحه",
  "طعنات",
  "عفوية",
  "كقاتل",
  "للقبو",
  "إليشا",
  "ايملي",
  "ممثلي",
  "اجلبه",
  "عددكم",
  "بعدهم",
  "وقضيت",
  "اشتغل",
  "بدؤوا",
  "ارقصي",
  "وخسرت",
  "قديسة",
  "مدرعة",
  "اكملي",
  "بدورة",
  "اتمام",
  "لتوقف",
  "حلقها",
  "ليــس",
  "لتنظر",
  "وعملي",
  "باتلر",
  "رباطة",
  "بصفته",
  "دعمنا",
  "الفرخ",
  "كرافت",
  "بانشي",
  "ميدوز",
  "بحمام",
  "وانني",
  "فانسي",
  "صربيا",
  "لتركك",
  "كرسيي",
  "شغلوا",
  "ذهابا",
  "إلــى",
  "دقيقا",
  "شبابه",
  "بابني",
  "غطائي",
  "وونكا",
  "وتدخل",
  "لعودة",
  "ليطلب",
  "لكنـه",
  "بحبها",
  "إبداء",
  "باعها",
  "نبتون",
  "وبشأن",
  "ثروتك",
  "لقوات",
  "حوائط",
  "الوخز",
  "حيتان",
  "متجول",
  "after",
  "قسائم",
  "ضبابي",
  "ولأنه",
  "ربـما",
  "منفضة",
  "كوبين",
  "جالجر",
  "لأفهم",
  "بنتلي",
  "العاج",
  "جعلوه",
  "بناءه",
  "ريتشى",
  "ملتوي",
  "نظروا",
  "مبهرة",
  "دانفي",
  "غوردو",
  "وتغير",
  "مبلغا",
  "جستين",
  "نعيدك",
  "لأظهر",
  "مخيفه",
  "قاتمة",
  "فندقك",
  "وديعة",
  "ليسمح",
  "كلعبة",
  "كيمبر",
  "عازمة",
  "اختبر",
  "ناديه",
  "لأسأل",
  "للربح",
  "بعدنا",
  "مختلط",
  "دفاتر",
  "والعب",
  "مجددآ",
  "للحزن",
  "داينا",
  "طفيلي",
  "لأحظى",
  "شمالي",
  "اهميه",
  "متعود",
  "نتمسك",
  "جايتس",
  "مضطره",
  "إجعلي",
  "بديهي",
  "الأوز",
  "نفسـه",
  "الحـب",
  "فضائح",
  "بحثوا",
  "الحفظ",
  "مرحله",
  "ذنبها",
  "ارتحت",
  "لمعان",
  "قيراط",
  "وتقتل",
  "كسرته",
  "خطتها",
  "وأنهي",
  "بعقله",
  "جيوبه",
  "وأعيد",
  "بصرية",
  "ملككم",
  "حـالك",
  "نحــن",
  "لتتصل",
  "مهبلك",
  "نيللي",
  "ديكسي",
  "نومهم",
  "قاتلي",
  "خدعته",
  "الخرف",
  "لاتدع",
  "محتوم",
  "هرائك",
  "جــدا",
  "روينا",
  "كابلن",
  "جولدن",
  "لطفلك",
  "عروقي",
  "نتعشى",
  "متأنق",
  "كتابى",
  "لمبنى",
  "حماسك",
  "الرتب",
  "فالكو",
  "مضارب",
  "نهائى",
  "طاعون",
  "إيغور",
  "إيثيل",
  "ليفاي",
  "مهينة",
  "ايهما",
  "شرابه",
  "هيتلر",
  "جديون",
  "لاعرف",
  "مقلقة",
  "بحقـك",
  "روزين",
  "نجاحه",
  "هنريك",
  "خاطري",
  "قبــل",
  "صالات",
  "وشيكة",
  "دفعتي",
  "عرجاء",
  "بتهور",
  "بيليك",
  "فكاهي",
  "هــنا",
  "موزبي",
  "هادلي",
  "كرامر",
  "نظفوا",
  "رحمها",
  "ملتقى",
  "لولاي",
  "ميتال",
  "بيرثا",
  "بأشعة",
  "نرجوا",
  "ماركي",
  "السهر",
  "نجتاز",
  "هابيل",
  "دارون",
  "فاريس",
  "للطرد",
  "لقلبك",
  "معروض",
  "معدية",
  "مفيده",
  "مكتشف",
  "اوشكت",
  "لصدمة",
  "بمئات",
  "لغتهم",
  "للنصف",
  "الفرج",
  "الجرم",
  "شابات",
  "خذوني",
  "وأمام",
  "وجهته",
  "ادراك",
  "فارقت",
  "غرفهم",
  "واردت",
  "حدثتك",
  "هجرها",
  "ولأنك",
  "اشاعة",
  "الخرق",
  "فخامة",
  "بقولي",
  "ركبنا",
  "للنفط",
  "لونغو",
  "شهيتك",
  "ميرزا",
  "غايوس",
  "عروقك",
  "راتبه",
  "منطلق",
  "داستي",
  "طليقا",
  "جنكيز",
  "عدتما",
  "الفتح",
  "الخضر",
  "حجرتك",
  "حصانا",
  "جهودك",
  "معدتى",
  "مسحور",
  "شريرا",
  "بليـر",
  "نظرهم",
  "قتلكم",
  "حركتي",
  "لنقدم",
  "ومضات",
  "وصاحب",
  "ويحمل",
  "وتيرة",
  "لوصول",
  "قيمتك",
  "مرونة",
  "فارغا",
  "كيندل",
  "الصبح",
  "اصغوا",
  "دياري",
  "الخزف",
  "مرورك",
  "إنقطع",
  "صفائح",
  "مخبأه",
  "ماكنا",
  "كافحت",
  "لتخبر",
  "نتفحص",
  "كيارا",
  "ناجحه",
  "الفجل",
  "شابيل",
  "إنعطف",
  "اسميه",
  "وجـود",
  "مسعور",
  "مركزك",
  "جايني",
  "وسأظل",
  "لنوقف",
  "صعدنا",
  "بروعة",
  "وفشلت",
  "مرارة",
  "عزيمة",
  "اخذهم",
  "واطلق",
  "لغسيل",
  "الإرث",
  "خليوي",
  "بمائة",
  "بمادة",
  "إخترق",
  "لتقرر",
  "بلهجة",
  "إقليم",
  "بقدرة",
  "إيهاب",
  "لاحصل",
  "ضحكات",
  "مشجعي",
  "خبيرا",
  "فرصـة",
  "عمودك",
  "بولدر",
  "خرجتم",
  "اكياس",
  "إجلبي",
  "hello",
  "امساك",
  "للغاز",
  "فيديا",
  "حرقها",
  "ويشعر",
  "جوهان",
  "بمتعة",
  "حاولو",
  "اجروا",
  "صادرة",
  "اسمـع",
  "مالهم",
  "ماعلي",
  "إخترع",
  "ميلفن",
  "زهرية",
  "رجولة",
  "لنبدا",
  "بفارغ",
  "شحنات",
  "وصورة",
  "ماضيي",
  "عربية",
  "جارتك",
  "قرارى",
  "لحركة",
  "جبهتك",
  "القيل",
  "فسيتم",
  "شأنهم",
  "جلبتي",
  "وكبير",
  "إخضاع",
  "طعنته",
  "المرق",
  "لليله",
  "إعتمد",
  "برايد",
  "وأتصل",
  "وأبوك",
  "منعني",
  "ليجلب",
  "عهدها",
  "عناكب",
  "بقدمك",
  "هاربا",
  "اؤذيك",
  "إذهـب",
  "لتقدم",
  "المزح",
  "لخداع",
  "برينس",
  "ديونه",
  "ضحكنا",
  "مورتن",
  "باعوا",
  "لزاما",
  "لعبها",
  "لوفاة",
  "بوضعك",
  "نترات",
  "إنسحب",
  "وكتبت",
  "فرصتى",
  "لأسمح",
  "النفخ",
  "حاضرا",
  "إلتزم",
  "نبضها",
  "لايحب",
  "الجده",
  "party",
  "خطفها",
  "موحدة",
  "مبعوث",
  "كوليا",
  "للرسم",
  "منعشة",
  "ماذلك",
  "بأداة",
  "حـتـى",
  "مؤلفة",
  "صامدة",
  "لاتحب",
  "جربوا",
  "وضعتم",
  "الخدش",
  "حركوا",
  "مانجو",
  "انتقم",
  "كاليه",
  "انزعي",
  "قنديل",
  "بمحمل",
  "حصلـت",
  "متنكر",
  "لاندو",
  "حريصا",
  "عقيدة",
  "ايوجد",
  "الرشد",
  "صدمني",
  "عنيفا",
  "ماكاي",
  "بالشر",
  "إسهال",
  "صرامة",
  "لثمان",
  "بوردو",
  "stand",
  "لنجمع",
  "للغوص",
  "ظريفا",
  "ايميت",
  "وهدوء",
  "روبنز",
  "نصدقك",
  "هروبك",
  "خارطة",
  "بيعهم",
  "بلدتك",
  "جيرزي",
  "القمع",
  "مزرعه",
  "لنفتح",
  "باربر",
  "باراك",
  "ملحمة",
  "بقدري",
  "ديربي",
  "فكرتم",
  "ناديك",
  "ويعني",
  "رأونا",
  "لوجهه",
  "نبالغ",
  "ادعوه",
  "اتقدم",
  "الحلي",
  "مفرغة",
  "البكم",
  "وقدمت",
  "ادافع",
  "منيعة",
  "الحزم",
  "الفرز",
  "ندعوك",
  "نتالي",
  "ثنائى",
  "واجهي",
  "إغواء",
  "ونعيش",
  "جلوسك",
  "خورخي",
  "اضعها",
  "بلوحة",
  "رحيمة",
  "إخفاق",
  "ذهبتى",
  "إسلام",
  "براعم",
  "مخطوب",
  "ملكها",
  "بحملة",
  "بالطب",
  "اخفضي",
  "ناينا",
  "هيلدي",
  "وكسرت",
  "بقربه",
  "ايدين",
  "وأرحل",
  "كسروا",
  "وأسود",
  "جولدي",
  "كذلكـ",
  "رأيتة",
  "ايميل",
  "وجاهز",
  "رونين",
  "شيانغ",
  "إبتهج",
  "اصطاد",
  "ذكرتي",
  "وبتلك",
  "كملكة",
  "وطعام",
  "كيوتو",
  "بارسا",
  "قائدا",
  "لأحمي",
  "بانكم",
  "بجسدي",
  "لباسي",
  "جائزه",
  "مساءك",
  "ثقتنا",
  "صونيا",
  "حارسك",
  "نــعم",
  "ونعيد",
  "باننى",
  "شارات",
  "فيلمه",
  "الازم",
  "ادعوا",
  "لينوس",
  "طوابع",
  "ضربتي",
  "نفعلة",
  "كريسب",
  "الحجة",
  "إغراق",
  "عقابه",
  "دافئا",
  "ابتكر",
  "فصلنا",
  "بايكر",
  "الأيل",
  "للطبخ",
  "جلبتك",
  "كانجي",
  "وأشرب",
  "النظم",
  "قطنية",
  "وشأنى",
  "لسابق",
  "دوركم",
  "قضيبى",
  "بعــد",
  "حائرة",
  "بمالك",
  "اصطحب",
  "دارسى",
  "غرائز",
  "فارمر",
  "بغموض",
  "الأله",
  "مرتاب",
  "البدر",
  "فـقـط",
  "غزلان",
  "مركزه",
  "great",
  "جنونى",
  "متينة",
  "وننسى",
  "اجيبي",
  "بنقطة",
  "علمتم",
  "وقائع",
  "بريئه",
  "بارعا",
  "حساسه",
  "الشرس",
  "للعرش",
  "وتطلق",
  "بمعظم",
  "بيونغ",
  "انتون",
  "الأشد",
  "وجهتي",
  "هافين",
  "جوابا",
  "البدل",
  "اعطهم",
  "دنبار",
  "الفين",
  "هامدة",
  "كوانغ",
  "مبدعة",
  "الشحم",
  "عويصة",
  "صرصور",
  "مذؤوب",
  "اشجار",
  "كوربس",
  "شرحها",
  "قرارة",
  "مجيئه",
  "نبدوا",
  "كمكان",
  "هكتار",
  "جذابا",
  "الفحل",
  "مماذا",
  "جبهته",
  "خاضعة",
  "نتدبر",
  "بلكنة",
  "كوركي",
  "لنغير",
  "مقعدا",
  "اليسا",
  "بعمـل",
  "نراهن",
  "لتترك",
  "بقطار",
  "هياكل",
  "عرضته",
  "ديوني",
  "للرقم",
  "انفاق",
  "قتالك",
  "وتسعة",
  "اجبني",
  "مملين",
  "اتبعي",
  "معدلة",
  "للشهر",
  "اقترف",
  "قداحة",
  "وقعتي",
  "بأختك",
  "بلغنا",
  "اتغير",
  "جنوبي",
  "ويلما",
  "كروكر",
  "إسترخ",
  "ظللنا",
  "فلتعد",
  "صامته",
  "متألم",
  "ويديك",
  "للفشل",
  "اغلقى",
  "مواسم",
  "مقابر",
  "رضيعة",
  "رادلي",
  "وأربع",
  "دافىء",
  "وعصير",
  "شربته",
  "الكسل",
  "ملكتك",
  "قصاصة",
  "خطفوا",
  "واتتك",
  "هوبير",
  "لسنـا",
  "ضيافة",
  "الصلع",
  "بذلنا",
  "الخلد",
  "جزيره",
  "نافعة",
  "الترم",
  "عقابي",
  "للآسف",
  "انقضت",
  "شعاري",
  "واقعه",
  "قارئة",
  "فراغك",
  "وبرغم",
  "لشقتي",
  "منظمه",
  "محصنة",
  "فهمـت",
  "دادلي",
  "لطفلة",
  "رهبان",
  "خيانه",
  "برودى",
  "لوضعك",
  "مهاجم",
  "حفظها",
  "هاوزر",
  "محبين",
  "واسمي",
  "حتمية",
  "لننقذ",
  "their",
  "نوعنا",
  "نكافح",
  "وكلمة",
  "لحمام",
  "وفيها",
  "القيد",
  "خاطرك",
  "إهانه",
  "ادوية",
  "كونية",
  "للأمم",
  "لمادة",
  "لتذكر",
  "نستلم",
  "الغصن",
  "إينزو",
  "لاحضر",
  "وألقى",
  "فارما",
  "وألعب",
  "عاودي",
  "بحذاء",
  "لكيلا",
  "مقاوم",
  "الضال",
  "خلوية",
  "طردهم",
  "نقدمه",
  "اصابك",
  "محسوب",
  "لأراه",
  "مصارف",
  "غضبهم",
  "لمعرض",
  "ذوبان",
  "ليستا",
  "مضروب",
  "لأعرض",
  "بشـيء",
  "بهاذا",
  "إرتدى",
  "مئتين",
  "واخبر",
  "افهمت",
  "بقبضة",
  "بيبسي",
  "جارزا",
  "اتهمت",
  "نفعـل",
  "لوحتك",
  "بعبور",
  "كورتس",
  "بورتو",
  "شاربي",
  "متوفى",
  "الحسد",
  "بكرات",
  "ثورين",
  "كتفها",
  "بديعة",
  "لموته",
  "إحراز",
  "وجدتى",
  "اوينز",
  "متكبر",
  "شابان",
  "وسآخذ",
  "لوالد",
  "عاصفه",
  "لنجري",
  "ايجار",
  "جنوده",
  "نعامل",
  "عطارد",
  "كاذبا",
  "مفصلة",
  "شروطك",
  "مخرجا",
  "doesn",
  "هيويت",
  "مطبخك",
  "منقطع",
  "حربنا",
  "للقرن",
  "فيردي",
  "موصول",
  "نرجسي",
  "لهاذا",
  "اتابع",
  "ولسون",
  "جعلوك",
  "نوربت",
  "وضعكم",
  "مسبار",
  "اعدام",
  "وسأرى",
  "مرساة",
  "دعمكم",
  "لاروش",
  "مجسات",
  "وينظر",
  "فهموا",
  "زواجى",
  "لموقف",
  "بديلا",
  "ريدلي",
  "مسكنك",
  "برشوة",
  "فرقته",
  "هانكس",
  "لتنهي",
  "جنيها",
  "شعبها",
  "برايم",
  "نتحول",
  "والشئ",
  "داندي",
  "افعلة",
  "بالخط",
  "ابليت",
  "حمودي",
  "نفسكم",
  "غروفر",
  "راينر",
  "صفحتي",
  "bring",
  "لضابط",
  "موريل",
  "اتجول",
  "اسفون",
  "طفلهم",
  "ممسكة",
  "همممم",
  "بعمري",
  "فقرات",
  "بمأزق",
  "مدروس",
  "كرسيه",
  "وأسمع",
  "فجروا",
  "متبنى",
  "ارادة",
  "هددني",
  "جراسي",
  "منـزل",
  "فيمـا",
  "رؤسكم",
  "لمنعه",
  "ادعاء",
  "شارته",
  "عصيبا",
  "اغتصب",
  "ميونغ",
  "للطعن",
  "وفاتي",
  "لتسمح",
  "كفيلم",
  "بقائه",
  "محققي",
  "قهوته",
  "الكفن",
  "اجتزت",
  "ماسيف",
  "قناني",
  "للفرد",
  "شرودر",
  "الغاب",
  "الذري",
  "اناني",
  "قائما",
  "هيكاب",
  "بجدول",
  "قضيتم",
  "مديره",
  "عشاءك",
  "وتكلم",
  "اظنها",
  "متمرس",
  "هنيئة",
  "اخاطر",
  "وسريع",
  "مزعجه",
  "داهية",
  "مسحها",
  "مررتي",
  "وسرعة",
  "مغمور",
  "بخداع",
  "بجسده",
  "كوارث",
  "رجولي",
  "غنائم",
  "عادته",
  "رتبنا",
  "بأبيك",
  "لنقله",
  "للزمن",
  "وربطة",
  "لهذين",
  "قذرين",
  "ذهنية",
  "بيضها",
  "متفتح",
  "مشوية",
  "ومخيف",
  "وإننا",
  "فضيلة",
  "قصيرا",
  "رالفي",
  "اختنق",
  "ومنها",
  "وجـدت",
  "عثرتي",
  "momas",
  "قبلوا",
  "خرافي",
  "كلووي",
  "غـراي",
  "الناي",
  "اسواء",
  "عمتها",
  "ذكراه",
  "وصنعت",
  "فوربز",
  "كندية",
  "واسمك",
  "لافعل",
  "نستقل",
  "ناسفة",
  "بوليت",
  "عداوة",
  "ورفضت",
  "الهاء",
  "وتشرب",
  "مركزا",
  "مانحن",
  "واجهه",
  "ناقشت",
  "فعلهم",
  "جبينك",
  "لتجلس",
  "بظروف",
  "الشوق",
  "مصاعب",
  "الكاف",
  "وتحرك",
  "نيادا",
  "نظـرة",
  "اتحكم",
  "white",
  "جونزي",
  "إتقان",
  "مخيلة",
  "ماكبث",
  "مروعا",
  "جربيه",
  "بيلـي",
  "الضأن",
  "قوالب",
  "الوحي",
  "فولفو",
  "وورلد",
  "بذكرى",
  "الآية",
  "محاكم",
  "الغنم",
  "اعرفة",
  "طلبتك",
  "بجعله",
  "ووفقا",
  "مربكة",
  "امورك",
  "ندعوا",
  "جربته",
  "ولدين",
  "لاقوم",
  "صفقتك",
  "عوائل",
  "روستي",
  "اقرأه",
  "guess",
  "جلستي",
  "بقلبه",
  "انضمت",
  "اتنفس",
  "ليالى",
  "اعيدي",
  "اشعار",
  "جونير",
  "لعلنا",
  "نعـرف",
  "للمحل",
  "نتسلق",
  "المفك",
  "هجوما",
  "نورتن",
  "بأسوء",
  "غربية",
  "الترف",
  "طلاقك",
  "فرحان",
  "الخشن",
  "إيلاى",
  "ماجور",
  "اترون",
  "اتسكع",
  "فطيره",
  "ليهتم",
  "بقيتم",
  "جرائد",
  "رخصته",
  "لنزهة",
  "انظرن",
  "بقتال",
  "فظاعة",
  "ادخله",
  "وحدكم",
  "بنظرك",
  "مشاكس",
  "ضاحية",
  "شكركم",
  "عجائز",
  "محلاة",
  "حبوبي",
  "ردائك",
  "رفضته",
  "ارمها",
  "كادوا",
  "لاينس",
  "بعلبة",
  "النقى",
  "باركا",
  "وصفتي",
  "وجلست",
  "جينين",
  "الصدف",
  "خنقها",
  "معبأة",
  "كفيلة",
  "لقطعة",
  "اطلعت",
  "عقدها",
  "دافئه",
  "حركها",
  "قتالي",
  "دوللي",
  "وقابل",
  "كيغان",
  "لسكان",
  "بالكم",
  "الرسغ",
  "شابلن",
  "حذرني",
  "ارموا",
  "ليصنع",
  "إنبوب",
  "ليجلس",
  "وردني",
  "اضواء",
  "هارست",
  "هيجان",
  "للصحة",
  "احضرك",
  "رياضى",
  "منصور",
  "صراخك",
  "وردنا",
  "هذيان",
  "شائكة",
  "رتبتك",
  "ويلعب",
  "حرروا",
  "وأمها",
  "طيارة",
  "لعبوا",
  "حفلته",
  "وسنجد",
  "كوكبك",
  "makes",
  "امراض",
  "ايران",
  "ولدها",
  "ارجعى",
  "وسألت",
  "انجزت",
  "بأخذك",
  "كولدن",
  "السوس",
  "انزله",
  "اقفال",
  "دفعتك",
  "فوربس",
  "لونجو",
  "اخوات",
  "الياء",
  "لاأحب",
  "الوجة",
  "انيتا",
  "فافعل",
  "جيركو",
  "صليني",
  "دييقو",
  "منتصب",
  "ثديها",
  "وأعطي",
  "باتسي",
  "فلنكن",
  "نتغلب",
  "الموج",
  "معكوس",
  "انطون",
  "عالقه",
  "وتوجد",
  "كنــت",
  "لاغسن",
  "للصين",
  "طاردت",
  "لتسأل",
  "جوالي",
  "ميترو",
  "ميلنر",
  "ليوني",
  "خالصة",
  "مغامر",
  "ذنوبي",
  "رهيبا",
  "وابنة",
  "فولير",
  "فقدها",
  "اصبعه",
  "شرطـة",
  "إنحني",
  "فولاذ",
  "كعكتك",
  "إيولس",
  "متابع",
  "فليست",
  "اخرجو",
  "which",
  "راسين",
  "انساه",
  "بطفلي",
  "لأبقي",
  "القمه",
  "لراحة",
  "مبتكر",
  "اقراص",
  "دميتك",
  "مسلمة",
  "معاني",
  "كجندي",
  "ملاحق",
  "وافتح",
  "ديكسن",
  "لمريض",
  "بيتـي",
  "قوسين",
  "ملاعب",
  "خطبتك",
  "النقط",
  "امنحك",
  "الارز",
  "مجانى",
  "بالين",
  "احتسي",
  "بالزي",
  "كباقي",
  "اطراف",
  "لأعثر",
  "مناصب",
  "بينجو",
  "حيالي",
  "نذهـب",
  "إبطاء",
  "بمزحة",
  "كلمـة",
  "ندبات",
  "صفقتي",
  "فلذلك",
  "لشعرك",
  "طاريء",
  "بإنهم",
  "لايلا",
  "مخبري",
  "فهمتى",
  "مقاسي",
  "فايفر",
  "كابون",
  "حجرات",
  "وتقضي",
  "الظفر",
  "وبصحة",
  "دافنى",
  "فارسي",
  "جالسه",
  "كماذا",
  "نبداء",
  "ضفادع",
  "بزوجك",
  "دغدغة",
  "عاودت",
  "اقلعت",
  "ماقمت",
  "ملاكا",
  "بنقله",
  "حقاني",
  "اخدمك",
  "ممثلا",
  "متعتي",
  "نظرته",
  "إكتمل",
  "مجتهد",
  "بمحرك",
  "ابيها",
  "بشعري",
  "القلة",
  "لقائه",
  "بقضيب",
  "وفاتك",
  "بساحة",
  "فأنني",
  "حذاري",
  "مجالي",
  "منزوع",
  "وبضعة",
  "عـليك",
  "رعدية",
  "جروحك",
  "بشرته",
  "ضاعفت",
  "طموحك",
  "فينسا",
  "هائله",
  "موبيل",
  "كونرز",
  "جوعان",
  "وسلطة",
  "واقفه",
  "ماجيك",
  "وأنكم",
  "كندرا",
  "خبرات",
  "بدانا",
  "ونصبح",
  "العضة",
  "شاهدى",
  "انكار",
  "افعال",
  "وفرقة",
  "كاحله",
  "كممثل",
  "الحمص",
  "مينكس",
  "زوبعة",
  "ليقدم",
  "باولي",
  "بيئية",
  "نتحرى",
  "اعالج",
  "واعثر",
  "نعومة",
  "بهدية",
  "خصوصي",
  "ارجون",
  "ونلقي",
  "رجعوا",
  "وجاءت",
  "ليشعر",
  "بتصرف",
  "لينجح",
  "كليتك",
  "دورجا",
  "مؤقته",
  "فيقول",
  "كذبنا",
  "القرط",
  "الفار",
  "نعرفك",
  "مختطف",
  "ادامز",
  "نستقر",
  "الصعق",
  "كولنز",
  "ويلدن",
  "برينر",
  "إخماد",
  "عرفني",
  "لأعلي",
  "صلعاء",
  "ويأكل",
  "ماجرى",
  "متفجر",
  "crazy",
  "رأسهم",
  "مضيعه",
  "ليزلى",
  "نبلاء",
  "لنقتل",
  "طاوله",
  "مليكي",
  "دانتى",
  "وسآتي",
  "إضغطي",
  "كاهلي",
  "مصرعه",
  "نشترى",
  "ثدييك",
  "ارايت",
  "مرشدة",
  "حانتك",
  "حاشية",
  "غرامه",
  "وتجلب",
  "جسيكا",
  "علمهم",
  "القمم",
  "اجمعي",
  "نطقها",
  "للسجل",
  "غرفكم",
  "هائجة",
  "علنية",
  "اتبعه",
  "الدود",
  "رايدن",
  "اصدقه",
  "مرتكب",
  "ماكاو",
  "قرائة",
  "ميلون",
  "اعلمي",
  "نحنحة",
  "فطوري",
  "بلاغي",
  "ثقيله",
  "بارتن",
  "الهزة",
  "الحضن",
  "فيريس",
  "وبسيط",
  "طابعة",
  "السبق",
  "إقرار",
  "دعاها",
  "مرهون",
  "براتب",
  "شيموس",
  "لنعطي",
  "طارىء",
  "الجيم",
  "وجلبت",
  "وجهنا",
  "اندفع",
  "موثقة",
  "شخــص",
  "بشفرة",
  "حلوتى",
  "زميلى",
  "نيمان",
  "قارنت",
  "هدوئك",
  "بحديث",
  "لتحقق",
  "ورقتي",
  "كويكب",
  "واشرب",
  "متجهه",
  "الاتي",
  "وننظر",
  "رايكر",
  "كمصدر",
  "مخلصا",
  "الصيت",
  "واخذت",
  "رأوني",
  "فرعية",
  "إيفيت",
  "لطاقم",
  "المول",
  "خدمنا",
  "إرهاق",
  "اشتكى",
  "للورد",
  "قضاها",
  "قداسة",
  "وأبيك",
  "اضاجع",
  "فاعلا",
  "بالحق",
  "الشاش",
  "عاطفى",
  "مهـلا",
  "إخلاص",
  "خططهم",
  "ميولر",
  "مسبوق",
  "شرقية",
  "منشور",
  "إختفي",
  "لتوهم",
  "ويقود",
  "ونبقى",
  "انكشف",
  "بيمان",
  "مكدير",
  "النعم",
  "اشربه",
  "اصلحت",
  "لأنهن",
  "اطعام",
  "while",
  "بتولي",
  "وأنام",
  "منصفة",
  "ويحدث",
  "ضمانة",
  "بترول",
  "ووالد",
  "الحيض",
  "دامية",
  "مبالي",
  "هربتي",
  "وقائد",
  "مرسلة",
  "معاكس",
  "وأقتل",
  "بشكوى",
  "بطفلة",
  "مفخخة",
  "وأجمل",
  "وتنام",
  "فيرات",
  "لإننى",
  "مركبه",
  "مثقوب",
  "شريدر",
  "نفسيه",
  "نوبتي",
  "بناها",
  "للسمك",
  "منظره",
  "دجاجه",
  "كعامل",
  "إقفزي",
  "لنجاح",
  "الوان",
  "لغتنا",
  "وقضاء",
  "بكرسي",
  "مرلين",
  "الذبح",
  "نرسله",
  "الومك",
  "لاهور",
  "الضجر",
  "وخذها",
  "لتشرب",
  "فيرنا",
  "لنطلب",
  "حملات",
  "ثاتشر",
  "ندفعه",
  "وأمور",
  "مجالا",
  "براقة",
  "نابغة",
  "دوبري",
  "وارجع",
  "خذوهم",
  "اكتسب",
  "لبقاء",
  "ورائى",
  "اريكا",
  "لجانب",
  "مالكه",
  "جالبة",
  "مستحق",
  "نوصلك",
  "مالوي",
  "وليـس",
  "كانني",
  "عموده",
  "شديدا",
  "رفعوا",
  "بعجلة",
  "نبيعه",
  "نتصور",
  "متسرع",
  "ضعفهم",
  "احزمي",
  "ذكائه",
  "كسالى",
  "بذراع",
  "كايدي",
  "امنحه",
  "وزيرة",
  "الصحه",
  "شكوكك",
  "بطعام",
  "وأدخل",
  "السنا",
  "لصالة",
  "لأكتب",
  "عقيمة",
  "منقوش",
  "دروسا",
  "شكلوا",
  "اراده",
  "كريست",
  "نقاوم",
  "وروحي",
  "شريره",
  "عيشها",
  "واردن",
  "ومجرد",
  "بالسم",
  "اعمله",
  "لوجهي",
  "كسبته",
  "نتغير",
  "ارميه",
  "دامغة",
  "لطخات",
  "للشحن",
  "اللفت",
  "دفنوا",
  "ثغرات",
  "اسمحى",
  "نخترق",
  "صممته",
  "الصرب",
  "ابتاع",
  "صنعتي",
  "للصدر",
  "هارسن",
  "مشورة",
  "مراوغ",
  "متحفظ",
  "خوفهم",
  "فضائى",
  "ننتبه",
  "نيبال",
  "قبضتم",
  "لوازم",
  "جولـز",
  "ماردل",
  "جميعه",
  "كاريس",
  "براغي",
  "قاحلة",
  "ننقسم",
  "خجولا",
  "راقصه",
  "لشرطي",
  "للآبد",
  "النمش",
  "مطوية",
  "جينية",
  "اعطوه",
  "دقايق",
  "مشوشه",
  "المشط",
  "نفهمه",
  "خساره",
  "رايتي",
  "ببيان",
  "قراءه",
  "غسلها",
  "مليئا",
  "منقار",
  "اخذتي",
  "رودلف",
  "نعسان",
  "ديباك",
  "لتروا",
  "ببرود",
  "لنمسك",
  "لفرصة",
  "لحظتك",
  "منافذ",
  "احموا",
  "اوتيس",
  "متألق",
  "جوميز",
  "بشروط",
  "نخسره",
  "بدنية",
  "هيهات",
  "الـدم",
  "رطوبة",
  "زياره",
  "دوائه",
  "فوقهم",
  "وريثة",
  "فينشي",
  "دينيز",
  "قسطرة",
  "عاتقه",
  "مرهقه",
  "لقلبي",
  "لأصنع",
  "حماتي",
  "موالي",
  "بعربة",
  "كورسو",
  "مهمـا",
  "جلوري",
  "وقطعت",
  "مدينا",
  "عبوره",
  "لنسأل",
  "مـــن",
  "متعجب",
  "بموتك",
  "بجلطة",
  "خذلته",
  "علوية",
  "كريما",
  "ويتون",
  "قسمين",
  "مورغن",
  "راكيل",
  "نونان",
  "بلورة",
  "للخمر",
  "جـاكي",
  "فوستو",
  "لتنال",
  "صلاتك",
  "المسه",
  "فلسوف",
  "وفرصة",
  "ادمان",
  "اشرحي",
  "غرامك",
  "حقيقا",
  "حواري",
  "كوكيز",
  "صغيـر",
  "احميك",
  "زائرة",
  "للحفر",
  "بصباح",
  "لتجمع",
  "حكمنا",
  "نتقبل",
  "وحدته",
  "دياغو",
  "بجعلك",
  "غوثري",
  "بهراء",
  "جلبهم",
  "راضين",
  "زعيمك",
  "لومها",
  "خافتة",
  "لاتثق",
  "وكـان",
  "البصق",
  "وولكر",
  "بخيار",
  "منتجي",
  "ليلته",
  "نزعها",
  "إقحام",
  "شكلهم",
  "وبنات",
  "فإننى",
  "البنس",
  "جوبتا",
  "كتبتي",
  "حماقه",
  "مهذبا",
  "ابانا",
  "مدربك",
  "وبعده",
  "بلغوا",
  "عجينة",
  "لوائح",
  "لشارع",
  "فاحشة",
  "نكسون",
  "وكانا",
  "ورقتك",
  "لنقبض",
  "إنزلق",
  "فرنسى",
  "فوكسي",
  "قواتك",
  "ومركز",
  "رقصات",
  "معادي",
  "اناقش",
  "مقدرا",
  "بمقاس",
  "محدده",
  "واغنر",
  "ممثله",
  "السين",
  "ويدخل",
  "لوبين",
  "ليسرق",
  "لنعقد",
  "للنفس",
  "خطأهم",
  "بوينس",
  "نغمات",
  "اتدري",
  "شهدنا",
  "مختار",
  "وكذبت",
  "نحوهم",
  "جسيمة",
  "فاديم",
  "بلباس",
  "لتحدي",
  "راجان",
  "إمرتي",
  "مخيبة",
  "بإبني",
  "حاخام",
  "ويدفع",
  "لنأتي",
  "نافذه",
  "حذرنا",
  "منهـم",
  "بـدأت",
  "وثقوا",
  "لنعلم",
  "دعاوى",
  "مكاسب",
  "طرحها",
  "بمهام",
  "جمالي",
  "لنائب",
  "هناكـ",
  "لقناة",
  "وننهي",
  "صدمتك",
  "بوستن",
  "هيليا",
  "ارتبط",
  "كسرنا",
  "غرامى",
  "قارعة",
  "مرفوع",
  "مصفحة",
  "فصائل",
  "لأترك",
  "طعنها",
  "انشات",
  "هيثرو",
  "هاتفت",
  "هوانج",
  "معقدا",
  "حارسه",
  "نزيهة",
  "الدوس",
  "إقبال",
  "المضغ",
  "ندائي",
  "بيلبو",
  "وظيفي",
  "بأسمه",
  "اضاءة",
  "ونموت",
  "بريتي",
  "ابداء",
  "ضخامة",
  "إتصلى",
  "قيمتي",
  "بتعلم",
  "للروس",
  "دودجر",
  "جيسوب",
  "جانيك",
  "كوالا",
  "لعميل",
  "هيملر",
  "مسموم",
  "لابنه",
  "شفتيه",
  "مهلكم",
  "نظريه",
  "اصطدت",
  "جزئين",
  "وأعلى",
  "مسودة",
  "شرطيه",
  "ومدير",
  "حضينا",
  "ديننا",
  "لأنظر",
  "دوغان",
  "ليلقي",
  "زبالة",
  "بخطاب",
  "موتوا",
  "لنرفع",
  "نائية",
  "ورقات",
  "اوغاد",
  "لتأتى",
  "ودرجة",
  "فراير",
  "جعلكم",
  "ليغطي",
  "هوراس",
  "للنمو",
  "لكلير",
  "وأبقي",
  "ولايه",
  "بتفحص",
  "إنسوا",
  "مقابض",
  "وأحبك",
  "الطمث",
  "جدتنا",
  "للولد",
  "عمران",
  "رسمته",
  "لطيـف",
  "مائلة",
  "ووحيد",
  "حررني",
  "بمنظر",
  "فيليز",
  "هنـري",
  "الغلق",
  "إنارة",
  "معلقا",
  "لكونى",
  "مقتبس",
  "وتخلص",
  "لتكسب",
  "محبطا",
  "رسلكم",
  "ذنوبك",
  "دافيز",
  "نقترح",
  "هيئته",
  "عروسي",
  "فاذهب",
  "واهية",
  "طفلته",
  "المحن",
  "بوووم",
  "موضعي",
  "الريس",
  "بذهني",
  "احارب",
  "كسيدة",
  "وجدوك",
  "وانضم",
  "نزلوا",
  "جراحك",
  "وسهلة",
  "بديله",
  "اووهـ",
  "بسبعة",
  "اجلهم",
  "للنصر",
  "لحيتي",
  "بأسمي",
  "وثقنا",
  "ضابطا",
  "موضعه",
  "بطفلك",
  "ليقضي",
  "مسنين",
  "ليهرب",
  "زوبيل",
  "رايزر",
  "غطينا",
  "فضولى",
  "احمقا",
  "إستلم",
  "دنيئة",
  "موقفا",
  "هاتفا",
  "وأقدم",
  "بالها",
  "راكيش",
  "لنمرح",
  "منهار",
  "واننا",
  "الذره",
  "صالحي",
  "ويسكى",
  "بفحصه",
  "خيالى",
  "وأدرك",
  "دفنته",
  "كيوكو",
  "لتفحص",
  "بوستر",
  "وأبعد",
  "زبونك",
  "عجالة",
  "ليعيد",
  "لأشعة",
  "رونون",
  "جولتي",
  "مثيـر",
  "عطوفة",
  "خريطه",
  "كونكم",
  "مجرمي",
  "حـاول",
  "colin",
  "lupin",
  "كموعد",
  "اقارب",
  "الطوف",
  "متعطش",
  "متسلل",
  "إداري",
  "زرعها",
  "بجعلي",
  "ثمانى",
  "الحجج",
  "جرادي",
  "عصبيه",
  "براكن",
  "بومير",
  "لرأسه",
  "لأشرب",
  "حلزون",
  "لوهان",
  "جولتك",
  "جياني",
  "متعتك",
  "روفيس",
  "وقاية",
  "إلتقت",
  "ومايك",
  "شرائي",
  "إصطدم",
  "اللهم",
  "قالقر",
  "وعائي",
  "زكريا",
  "وعالم",
  "مكتظة",
  "لتركي",
  "وتدفع",
  "نائبي",
  "لنقلق",
  "مهزوم",
  "خسرتم",
  "بارعه",
  "البدو",
  "وأكتب",
  "نؤذيك",
  "المكر",
  "جروحه",
  "البطئ",
  "روسوم",
  "كيلفن",
  "بصحيح",
  "لحظتي",
  "وماري",
  "مدمنا",
  "إبقيا",
  "مطبوع",
  "لسائق",
  "جهزنا",
  "ملتهب",
  "هربرت",
  "انحرف",
  "لعابك",
  "بباقي",
  "لؤلؤة",
  "بتوعك",
  "جوهري",
  "قصصهم",
  "ميديا",
  "كــلا",
  "بمجلة",
  "بصلصة",
  "مريسا",
  "لاحظي",
  "باأنك",
  "خاتمه",
  "فاتكم",
  "إغاثة",
  "لاتقم",
  "وطاقم",
  "عــام",
  "رفضتي",
  "مظروف",
  "كوبون",
  "بسلطة",
  "بناؤه",
  "جبيرة",
  "رمسيس",
  "انفقت",
  "برميه",
  "ادخلت",
  "راجيش",
  "ايريس",
  "محجوب",
  "إطالة",
  "وأعثر",
  "نزلاء",
  "إبدئي",
  "وخائف",
  "فيفان",
  "لوسون"
];

export const day = (): number => {
  const TODAY = new Date();
  const start = new Date(2022, 0, 27);
  const diff = Number(TODAY) - Number(start);
  return Math.floor(diff / (1000 * 60 * 60 * 24));
};

export const getWordOfTheDay = async (): Promise<string> => {
  const db = getFirestore(app);

  const citiesRef = collection(db, "activeWord");

  const q = query(citiesRef, where("isActive", "==", true));
  const querySnapshot = await getDocs(q);
  const word: string = querySnapshot.docs[0].data()["word"];

  return word;
};
